import { callApiToServer } from "../callApi";

export async function deleteModule(authtoken, moduleId) {
  console.log(moduleId);
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      "topic_id": moduleId
    });


    let endUrl = "v1/auth/admin/delete-course-session-topic";

    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "DELETE", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
