import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { getAllLanguage } from "../../Graphs/Language/listLanguage";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteLanguage } from "../../Graphs/Language/deleteLanguage";
import { changeStatus } from "../../Graphs/Language/changeStatus";
import { editLanguage } from "../../Graphs/Language/editLanguage";
import { searchLanguage } from "../../Graphs/Language/searchLanguage";
import { EditModal } from "./EditModal";
import { checkPermission } from "../AdminUser/CheckPremission";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class LanguageRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedLanguage = this.getLimitedLanguage.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      languageSelectedData: null,
    });
  }

  modalRef;

  async getLimitedLanguage(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchLanguageData(start, end);
        } else {
          //call normal user Data
          this.listLanguageData(start, end);
        }
      }
    );
  }

  listSearchLanguageData = async (start, end) => {
    try {
      const {
        authToken,
        setUserLogout,
        setListLanguageData,
        setListLanguageDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let languageData = await searchLanguage(
        authToken,
        searchText,
        start,
        end
      );
      if (languageData.status == 200) {
        //set user Data
        await setListLanguageData(languageData.data);
        await setListLanguageDataTotal(languageData.total);

        this.setState({
          isLoading: false,
          data: languageData.data,
        });
      } else if (languageData.status == 401) {
        await setUserLogout();
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listLanguageData = async (start, end) => {
    try {
      const {
        authToken,
        setUserLogout,
        setListLanguageData,
        setListLanguageDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let languageData = await getAllLanguage(authToken, start, end);

      console.log(languageData.data);

      if (languageData.status == 200) {
        //set user Data
        await setListLanguageData(languageData.data);
        await setListLanguageDataTotal(languageData.total);

        this.setState({
          isLoading: false,
          data: languageData.data,
        });
      } else if (languageData.status == 401) {
        await setUserLogout();
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        languageSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      languageSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      // width: 150,
      key: "name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title=" Name">
                    <div>{item.name ? item.name : "-"}</div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: "Total Courses",
    //   key: "total_courses",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <Tooltip placement="bottom" title="Total Courses">
    //           <div>
    //             {item.total_courses ? String(item.total_courses) : "-"}
    //           </div>
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    // },
    {
      title: "Active",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Active">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      // width : 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {checkPermission(
              this.props.loginUserData.permissions,
              "language",
              "edit"
            ) && (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            )}
            {checkPermission(
              this.props.loginUserData.permissions,
              "language",
              "delete"
            ) && (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteLanguage.bind(this, item)}
                    title="Are you sure you want to delete Language?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  async deleteLanguage(item) {
    try {
      const { authToken, setUserLogout, searchText } = this.props;
      const { startIndex, limitNumber } = this.state;
      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteLanguage(authToken, item._id);

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Language",
          description: "Successfully deleted",
        });
        if (searchText != null && searchText != "") {
          this.listSearchLanguageData(startIndex, limitNumber);
          return;
        }
        this.listLanguageData(startIndex, limitNumber);
      } else if (deleteData.status == 401) {
        await setUserLogout();
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Language",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const { authToken, setUserLogout, searchText } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item._id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Language Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchLanguageData(startIndex, limitNumber);
          return;
        }
        this.listLanguageData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setUserLogout();
      }
    } catch (err) {
      notification["error"]({
        message: "Change Language Status",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (languageObject, languageId) => {
    try {
      const { authToken, setUserLogout, searchText } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editLanguage(
        authToken,
        languageObject,
        languageId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Language Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchLanguageData(startIndex, limitNumber);
          return;
        }
        this.listLanguageData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setUserLogout();
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Language",
        description: e,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedLanguage(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { listLanguageData, listLanguageDataTotal, searchLoader, loginUserData } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          dataSource={listLanguageData}
          pagination={{
            total: listLanguageDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.languageSelectedData &&
        checkPermission(loginUserData.permissions, "language", "edit") ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            languageSelectedData={this.state.languageSelectedData}
            onSubmit={this.editSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listLanguageData: state.language.listLanguageData,
  listLanguageDataTotal: state.language.listLanguageDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLogout: () => {
      dispatch({
        type: "USER_LOGOUT",
      });
    },
    setListLanguageData: (listLanguageData) => {
      dispatch({
        type: "SET_LIST_LANGUAGE_DATA",
        listLanguageData: listLanguageData,
      });
    },
    setListLanguageDataTotal: (listLanguageDataTotal) => {
      dispatch({
        type: "SET_LIST_LANGUAGE_DATA_TOTAL",
        listLanguageDataTotal: listLanguageDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LanguageRecordsTable));
