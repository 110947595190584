import { callGetApiToServer } from '../callApi';

export async function dropDownListAllGroupUsers(
	authToken,
	groupId,
	skip,
	limit
) {
	try {
		let header = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			"authorization": "Berear " + authToken
		}

		// let endUrl = "v1/auth/group-members/" + groupId + "/" + skip + "/" + limit;
		let endUrl = "v1/auth/group-members/" + groupId;

		let responseData = await callGetApiToServer(header, "GET", endUrl);

		return responseData;
	} catch (err) {
		throw err;
	}
}
