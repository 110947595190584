import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { listAllDropDownCategory } from "../../Graphs/Category/listAllDropDownCategory";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedCategory: "",
      errors: {
        selectedCategory: "",
      },
    };
  }

  input;
  allCategories = [];

  async componentDidMount() {
    const { authToken, data } = this.props;

    let categoryData = await listAllDropDownCategory(authToken);

    if (categoryData.data && categoryData.data.length >= 1) {
      categoryData.data.map((data, index) => {
        this.allCategories.push(
          <Option value={data._id}>
            {data.name}
          </Option>
        );
      });
    }

    this.setState({
      selectedCategory: data.category_id._id,
    });

  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  handleSubmit(values, action) {

    const { selectedCategory, errors } = this.state;

    const dummyErrors = { ...errors };

    dummyErrors.selectedCategory = "";

    let isValidation = false;

    if (!selectedCategory) {
      isValidation = true;
      dummyErrors.selectedCategory = "Please select category";
    }

    this.setState({
      errors: dummyErrors
    });

    if (isValidation) {
      return;
    }

    values.category_id = selectedCategory;
    values.sub_category_id = this.props.data._id;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {

    const { selectedCategory } = this.state

    return (
      <div>
        <Formik
          initialValues={{
            name: this.props.data.name
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Name
                    <Input
                      id="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Category
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) => this.changeCategory(value)}
                      placeholder="Select Category"
                      showSearch
                      value={selectedCategory}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.allCategories}
                    </Select>
                    {this.state.errors.selectedCategory ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {this.state.errors.selectedCategory}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}
