import { Modal, Button, Divider, notification } from "antd";
import Title from "./Title";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as la from "lodash";
import { Link } from "react-router-dom";
import { editAdminPassword } from "../../Graphs/Admin/editAdminPassword";

const { Content } = Layout;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class ChangeAdminPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      timeString: "",
      roleType: "1",
      errors: {},
    };
  }

  input;

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm(values) {
    const errors: any = {};
    console.log("Validating form -- ", values);

    if (!values.old_password) {
      errors.old_password = "Old Password is required";
    } else if (values.old_password !== "" && values.old_password.length < 8) {
      errors.old_password = "At least 8 characters required";
    }
    if (!values.new_password) {
      errors.new_password = "New Password is required";
    } else if (values.new_password !== "" && values.new_password.length < 8) {
      errors.new_password = "At least 8 characters required";
    }
    if (!values.confirm_new_password) {
      errors.confirm_new_password = "Confirm New Password is required";
    } else if (
      values.confirm_new_password !== "" &&
      values.confirm_new_password.length < 8
    ) {
      errors.confirm_new_password = "At least 8 characters required";
    }

    if (values.new_password !== values.confirm_new_password) {
      errors.confirm_new_password =
        "New Password and Confirm New Password does not match";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {
    console.log(values);
    console.log("Basic Value - ", values);

    this.changeAdminPassword(values);

    this.setState({
      visible: false,
    });
  }

  async changeAdminPassword(adminObject) {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;

      this.setState({
        isLoading: true,
      });
      console.log("edit user id ================================");
      console.log(adminObject);
      let resData = await editAdminPassword(authToken, adminObject);
      console.log(resData);

      if (resData.status == 500) {
        throw resData.message;
      }

      if (resData.status == 200) {
        notification["success"]({
          message: "Edit Admin Profile",
          description: "Password changed successfully, Please login again",
        });
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else if (resData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!!error in editSubmit function", e);
      notification["error"]({
        message: "Edit Admin Profile",
        description: e,
      });
    }
  }

  render() {
    return (
      <div>
        <div>
          <Title parent="Edit Password" title="Admin Profile" />
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                old_password: "",
                new_password: "",
                confirm_new_password: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div className="container-fluid p-20">
                    <div className="form-group">
                      <div className="formik-field">
                        Old Password
                        <Input
                          id="old_password"
                          type="password"
                          placeholder="Old Password"
                          value={values.old_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.old_password && touched.old_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.old_password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="formik-field">
                        New Password
                        <Input
                          id="new_password"
                          type="password"
                          placeholder="New Password"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_password && touched.new_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.new_password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="formik-field">
                        Confirm New Password
                        <Input
                          id="confirm_new_password"
                          type="password"
                          placeholder="Confirm New Password"
                          value={values.confirm_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_new_password &&
                        touched.confirm_new_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.confirm_new_password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update Password
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>

          {/* <div style={{ float: "right" }}>
                        <Link to="/edit-profile/mail">
                            <Button
                                // type="primary"
                                style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }}
                            >
                                Chnage Email
                            </Button>
                        </Link>
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangeAdminPassword));
