import React, { useState, Fragment } from "react";
import logo from "../../../assets/images/endless-logo.png";
import Language from "./language";
import UserMenu from "./userMenu";
import Notification from "./notification";
import SearchHeader from "./searchHeader";
import { Link } from "react-router-dom";
import {
  AlignLeft,
  Maximize,
  Bell,
  MessageCircle,
  MoreHorizontal,
  Search,
} from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { searchAdmin } from "../../../Graphs/Admin/searchAdmin";
import { searchUser } from "../../../Graphs/User/searchUser";
import { searchCategory } from "../../../Graphs/Category/searchCategory";
import { searchSubCategory } from "../../../Graphs/SubCategory/searchSubCategory";
import { searchLanguage } from "../../../Graphs/Language/searchLanguage";
import { searchCourse } from "../../../Graphs/Course/searchCourse";
import { searchModule } from "../../../Graphs/Module/searchModule";
import { searchCourseSession } from "../../../Graphs/CourseSession/searchCourseSession";
import { searchCourseSessionTopic } from "../../../Graphs/CourseSessionTopic/searchCourseSessionTopic";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);

  const primaryColor = localStorage.getItem("primary_color");

  const provideSearchRoutes = [
    "ADMIN",
    "USER",
    "CATEGORY",
    "SUB_CATEGORY",
    "LANGUAGE",
    "COURSE",
    "MODULE",
    "COURSESESSION",
    "COURSESESSIONTOPIC",
  ]; //routes name
  const placeHolderValues = {
    ADMIN: "Admin",
    USER: "User",
    CATEGORY: "Category",
    SUB_CATEGORY: "Sub Category",
    LANGUAGE: "Language",
    COURSE: "Course",
    MODULE: "Module",
    COURSESESSION: "Session",
    COURSESESSIONTOPIC: "Topic",
  }; //placeholder name based on route name

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  };

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const dispatch = useDispatch();
  const searchText = useSelector((content) => content.auth.searchText);
  const routeName = useSelector((content) => content.auth.routeName);
  const authToken = useSelector((content) => content.auth.authToken);

  async function handleSearchKeyword(textValue) {
    console.log("!!!!!!!!!!!onchange method", textValue, searchText);

    if (routeName === "ADMIN") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchAdminData(textValue);
    }

    if (routeName === "USER") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchUserData(textValue);
    }

    if (routeName === "CATEGORY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCategoryData(textValue);
    }

    if (routeName === "SUB_CATEGORY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchSubCategoryData(textValue);
    }

    if (routeName === "LANGUAGE") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchLanguageData(textValue);
    }

    if (routeName === "COURSE") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCourseData(textValue);
    }

    if (routeName === "MODULE") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchModuleData(textValue);
    }

    if (routeName === "COURSESESSION") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchSessionData(textValue);
    }

    if (routeName === "COURSESESSIONTOPIC") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchTopicData(textValue);
    }
  }

  async function listSearchAdminData(textValue) {
    let adminData = await searchAdmin(authToken, textValue, 0, 10);

    if (adminData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_ADMIN_DATA",
        // listAdminData: adminData.data.docs,
        listAdminData: adminData.data,
      });
      await dispatch({
        type: "SET_LIST_ADMIN_DATA_TOTAL",
        listAdminDataTotal: adminData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchUserData(textValue) {
    let userData = await searchUser(authToken, textValue, 0, 10);

    if (userData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_USER_DATA",
        // listUserData: userData.data.docs,
        listUserData: userData.data,
      });
      await dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: userData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCategoryData(textValue) {
    let categoryData = await searchCategory(authToken, textValue, 0, 10);

    if (categoryData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        // listCategoryData: categoryData.data.docs,
        listCategoryData: categoryData.data,
      });
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: categoryData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchSubCategoryData(textValue) {
    let subCategoryData = await searchSubCategory(authToken, textValue, 0, 10);

    if (subCategoryData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA",
        // listCategoryData: subCategoryData.data.docs,
        listSubCategoryData: subCategoryData.data,
      });
      await dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA_TOTAL",
        listSubCategoryDataTotal: subCategoryData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchLanguageData(textValue) {
    let languageData = await searchLanguage(authToken, textValue, 0, 10);

    if (languageData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_LANGUAGE_DATA",
        // listCategoryData: languageData.data.docs,
        listLanguageData: languageData.data,
      });
      await dispatch({
        type: "SET_LIST_LANGUAGE_DATA_TOTAL",
        listLanguageDataTotal: languageData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCourseData(textValue) {
    let courseData = await searchCourse(authToken, textValue, 0, 10);

    if (courseData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COURSE_DATA",
        // listCategoryData: courseData.data.docs,
        listCourseData: courseData.data,
      });
      await dispatch({
        type: "SET_LIST_COURSE_DATA_TOTAL",
        listCourseDataTotal: courseData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchModuleData(textValue) {
    let moduleData = await searchModule(authToken, textValue, 0, 10);

    if (moduleData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_MODULE_DATA",
        // listModuleData: moduleData.data.docs,
        listModuleData: moduleData.data,
      });
      await dispatch({
        type: "SET_LIST_MODULE_DATA_TOTAL",
        listModuleDataTotal: moduleData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchSessionData(textValue) {
    let sessionData = await searchCourseSession(authToken, textValue, 0, 10);

    if (sessionData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COURSE_SESSION_DATA",
        listCourseSessionData: sessionData.data,
      });
      await dispatch({
        type: "SET_LIST_COURSE_SESSION_DATA_TOTAL",
        listCourseSessionDataTotal: sessionData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchTopicData(textValue) {
    let topicData = await searchCourseSessionTopic(authToken, textValue, 0, 10);

    if (topicData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COURSE_SESSION_TOPIC_DATA",
        // listCategoryData: topicData.data.docs,
        listCourseSessionTopicData: topicData.data,
      });
      await dispatch({
        type: "SET_LIST_COURSE_SESSION_TOPIC_DATA_TOTAL",
        listCourseSessionTopicDataTotal: topicData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                {/* <img className="img-fluid" src={logo} alt="" /> */}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  Trainovate
                </div>
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javascript" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? "" : "open"}`}>
              <li>
                <Fragment>
                  <div>
                    {provideSearchRoutes.includes(routeName) ? (
                      <form className="form-inline search-form">
                        <div className="form-group">
                          <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder={
                              placeHolderValues && placeHolderValues[routeName]
                                ? "Search " + placeHolderValues[routeName]
                                : "Search"
                            }
                            onChange={(e) =>
                              handleSearchKeyword(e.target.value)
                            }
                          />
                          <span className="d-sm-none mobile-search">
                            <Search />
                          </span>
                        </div>
                      </form>
                    ) : null}
                  </div>
                </Fragment>
              </li>
              {/* <li>
                <SearchHeader />
              </li> */}
              {/* <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li> */}
              {/* <li className="onhover-dropdown">
                <a className="txt-dark" href="#javascript">
                  <h6>EN</h6></a>
                <Language />
              </li>
              <li className="onhover-dropdown">
                <Notification />
                <Bell />
                <span className="dot"></span>
                <Notification />
              </li> */}
              <li style={{ marginRight: "0px" }}>
                {/* <a href="#javascript" onClick={showRightSidebar}>
                  <MessageCircle />
                  <span className="dot"></span>
                </a> */}
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li>
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
