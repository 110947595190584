/**
 * check the module permission has given to the logged in admin user or not
 * @param {array} permissionData login user permission data
 * @param {string} module module name
 * @param {string} action action name
 * @returns {boolean} return the boolean
 */
export const checkPermission = (permissionData, module, action) => {
	// if (permissionData && permissionData[module] && permissionData[module][action]) {
	// 	return true;
	// }
	// return false;
	return true;
}