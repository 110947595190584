import {
    SET_LIST_ADMIN_DATA,
    SET_LIST_ADMIN_DATA_TOTAL,
  } from "../constant/actionTypes";
  
  const initial_state = {
    listAdminData: null,
    listAdminDataTotal: 0,
  };
  
  export default (state = initial_state, action) => {
    switch (action.type) {
      case SET_LIST_ADMIN_DATA:
        return {
          ...state,
          loading: false,
          listAdminData: action.listAdminData,
        };
  
      case SET_LIST_ADMIN_DATA_TOTAL:
        return {
          ...state,
          loading: false,
          listAdminDataTotal: action.listAdminDataTotal,
        };
  
      default:
        return { ...state };
    }
  };
  