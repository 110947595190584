import {
	Table,
	Button,
	Switch,
	Popconfirm,
	Tooltip,
	notification,
	Spin, Tag,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { listUserGroup } from "../../Graphs/Group/listUserGroup";
import { listCourseGroup } from "../../Graphs/Group/listCourseGroup";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteGroup } from "../../Graphs/Group/deleteGroup";
import { changeStatus } from "../../Graphs/Group/changeStatus";
import { editGroup } from "../../Graphs/Group/editGroup";
import { searchGroup } from "../../Graphs/Group/searchGroup";
import { EditModal } from "./EditModal";
import { EditGroupPermission } from "./EditGroupPermission";

class GroupRecordsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			isLoading: true,
			startIndex: 0,
			limitNumber: 10,
			pagination: {
				pageSize: 10,
				current: 1,
			},
		};
		this.handleTableChange = this.handleTableChange.bind(this);
		this.getLimitedGroup = this.getLimitedGroup.bind(this);
	}

	async componentDidMount() {
		this.setState({
			data: this.props.data,
			isLoading: false,
			groupSelectedData: null,
		});
	}

	modalRef;
	groupPermissionModalRef;

	async getLimitedGroup(pagination) {
		const { searchText } = this.props;
		console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
		const start =
			pagination.current * pagination.pageSize - pagination.pageSize;
		const end = pagination.pageSize;

		this.setState(
			{
				isLoading: true,
				startIndex: start,
				limitNumber: end,
			},
			async () => {
				if (searchText != null && searchText !== "") {
					//get search text props from redux store
					this.listSearchGroupData(start, end);
				} else {
					//call normal user Data
					this.listGroupData(start, end);
				}
			}
		);
	}

	listSearchGroupData = async (start, end) => {
		try {
			const {
				authToken,
				history,
				setLoginFlag,
				setUserData,
				setUserToken,
				setListGroupData,
				setListGroupDataTotal,
				searchText,
				course_id,
			} = this.props;
			this.setState({
				isLoading: true,
			});

			let groupData = await searchGroup(authToken, searchText, start, end);

			if (groupData.status === 200) {
				//set user Data
				await setListGroupData(groupData.data);
				await setListGroupDataTotal(groupData.total);

				this.setState({
					isLoading: false,
					data: groupData.data,
				});
			} else if (groupData.status == 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			console.log("!!!!!!!list search user Data printed here", e);
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	};

	listGroupData = async (key) => {
		const {
			authToken,
			history,
			setLoginFlag,
			setUserData,
			setUserToken,
			setListGroupData,
			setListGroupDataTotal,
			course_id,
			activeTabKey
		} = this.props;
		try {
			let groupData = [];
			if (activeTabKey === 1) {
				groupData = await listUserGroup(authToken, 0, 10, course_id);
			} else {
				groupData = await listCourseGroup(authToken, 0, 10, course_id);
			}
			if (groupData.status === 200) {
				//set group data
				await setListGroupData(groupData.data);
				await setListGroupDataTotal(groupData.total);

				this.setState({
					groupList: groupData.data,
					groupTotal: groupData.total,
				})
			} else if (groupData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			throw e;
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	};

	showModal(item) {
		this.setState(
			{
				groupSelectedData: item,
			},
			() => {
				this.modalRef.show();
			}
		);
	}

	showAssignGroupAdminModal(item) {
		this.setState(
			{
				groupSelectedData: item,
			},
			() => {
				this.groupPermissionModalRef.show();
			}
		);
	}

	handleDetailsModalclose() {
		this.setState({
			groupSelectedData: null,
		});
	}

	counter = 0;
	tempCounter = 0;
	columns = [
		{
			title: "Sr. No.",
			width: 150,
			key: "serial_number",
			render: (text, item, index) => {
				return (
					<div>
						<span>{++index + this.tempCounter}</span>
					</div>
				);
			},
		},
		{
			title: "Name",
			width: 150,
			key: "name",
			render: (text, item, index) => {
				console.log("user record --> ", item);
				return (
					<div>
						<strong>
							<Tooltip placement="bottom" title="Name">
								<div>{item.name ? item.name : "-"}</div>
							</Tooltip>
						</strong>
					</div>
				);
			},
		},
		{
			title: "Course",
			width: 150,
			key: "course",
			render: (text, item, index) => {
				console.log("user record --> ", item);
				return (
					<div>
						<strong>
							<Tooltip placement="bottom" title="Course">
								<div>
									{item.courseData &&
									item.courseData[0] &&
									item.courseData[0].name
										? item.courseData[0].name
										: "-"}
								</div>
							</Tooltip>
						</strong>
					</div>
				);
			},
		},
		{
			title: "Private / Public",
			width: 100,
			key: "Private",
			render: (text, item, index) => {
				return (
					<div>
						<Tooltip placement="bottom" title="Private / Public">
							{item.is_private ? (
								<Tag color="blue">Private</Tag>
							) : (
								<Tag color="success">Public</Tag>
							)}
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: "Restricted",
			width: 100,
			key: "Restricted",
			render: (text, item, index) => {
				return (
					<div>
						<Tooltip placement="bottom" title="Restricted">
							{item.is_restricted ? (
								<Tag color="alert">Restricted</Tag>
							) : (
								<Tag color="blue">Not Restricted</Tag>
							)}
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: "Active",
			width: 100,
			key: "is_active",
			render: (text, item, index) => {
				return (
					<div>
						{item.is_active != null ? (
							<Tooltip placement="bottom" title="Group Activation">
								{
									<Switch
										checked={item.is_active}
										style={{
											backgroundColor: item.is_active
												? this.props.primaryColor
												: "#bababa",
										}}
										onChange={this.onChange.bind(this, item)}
									/>
								}
							</Tooltip>
						) : (
							"-"
						)}
					</div>
				);
			},
		},
		{
			title: "Actions",
			width: 130,
			key: "action",
			render: (text, item, index) => {
				return (
					<div style={{ display: "inline-block", width: "100%" }}>
						{/* <div mr="5px" style={{ float: "left" }}>
							<Tooltip placement="bottom" title="Edit">
								<Button
									shape="circle"
									icon={<EditOutlined/>}
									onClick={() => {
										this.showModal(item);
									}}
								/>
							</Tooltip>
						</div> */}
						{this.props.activeTabKey === "1" && (
							<div ml="15px" mr="5px" style={{ float: "left", marginLeft: "10px" }}>
								<Tooltip placement="bottom" title="Assign Group Admin">
									<Button
										shape="circle"
										icon={<UserOutlined/>}
										onClick={() => {
											this.showAssignGroupAdminModal(item);
										}}
									/>
								</Tooltip>
							</div>
						)}
						{/* <div ml="15px" mr="5px" style={{ float: "left", marginLeft: "10px" }}>
							<Tooltip placement="bottom" title="Delete">
								<Popconfirm
									onConfirm={this.deleteGroupData.bind(this, item)}
									title="Are you sure you want to delete Group?"
								>
									<Button shape="circle" icon={<DeleteOutlined/>}/>
								</Popconfirm>
							</Tooltip>
						</div> */}
					</div>
				);
			},
		},
	];

	async deleteGroupData(item) {
		try {
			const {
				authToken,
				history,
				setLoginFlag,
				setUserData,
				setUserToken,
				searchText,
			} = this.props;
			const { startIndex, limitNumber } = this.state;

			this.setState({
				isLoading: true,
			});
			let deleteData = await deleteGroup(authToken, item._id);

			if (deleteData.status === 200) {
				notification["success"]({
					message: "Delete Group",
					description: "Successfully deleted",
				});

				/*if (searchText != null && searchText !== "") {
					this.listSearchGroupData(startIndex, limitNumber);
					return;
				} */
				this.listGroupData(startIndex, limitNumber);
			} else if (deleteData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			notification["error"]({
				message: "Delete Group",
				description: e,
			});
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	async onChange(item, checked) {
		try {
			const {
				authToken,
				history,
				setLoginFlag,
				setUserData,
				setUserToken,
				searchText,
			} = this.props;
			const { startIndex, limitNumber } = this.state;

			this.setState({
				isLoading: true,
			});
			let statusData = await changeStatus(authToken, item._id, checked);
			if (statusData.status === 200) {
				notification["success"]({
					message: "Change Group Status",
					description: "Successfully changed status",
				});
				if (searchText != null && searchText !== "") {
					this.listSearchGroupData(startIndex, limitNumber);
					return;
				}
				this.listGroupData(startIndex, limitNumber);
			} else if (statusData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (err) {
			notification["error"]({
				message: "Change Group Status",
				description: err,
			});
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	editSubmit = async (groupObject) => {
		try {
			const {
				authToken,
				history,
				setLoginFlag,
				setUserData,
				setUserToken,
				searchText,
			} = this.props;
			const { startIndex, limitNumber } = this.state;

			this.setState({
				isLoading: true,
			});
			let statusData = await editGroup(authToken, groupObject);
			if (statusData.status === 200) {
				notification["success"]({
					message: "Edit Group Status",
					description: "Successfully edited",
				});
				if (searchText != null && searchText !== "") {
					this.listSearchGroupData(startIndex, limitNumber);
					return;
				}
				this.listGroupData(startIndex, limitNumber);
			} else if (statusData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			notification["error"]({
				message: "Edit Group",
				description: e,
			});
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	};

	assignGroupAdminSubmit = async (groupObject) => {
		try {
			const {
				authToken,
				history,
				setLoginFlag,
				setUserData,
				setUserToken,
				searchText,
			} = this.props;
			const { startIndex, limitNumber } = this.state;

			this.setState({
				isLoading: true,
			});
			let statusData = await editGroup(authToken, groupObject);
			if (statusData.status === 200) {
				notification["success"]({
					message: "Update Group Admin User",
					description: "Successfully Updated Group Admin Users",
				});
				if (searchText != null && searchText !== "") {
					this.listSearchGroupData(startIndex, limitNumber);
					return;
				}
				this.listGroupData(startIndex, limitNumber);
			} else if (statusData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			notification["error"]({
				message: "Update Group Admin User",
				description: e,
			});
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	};

	handleTableChange(pagination, filters) {
		this.setState(
			{
				pagination,
			},
			() => this.getLimitedGroup(pagination)
		);
	}

	render() {
		const { isLoading } = this.state;
		const { listGroupData, listGroupDataTotal, searchLoader, course_id, activeTabKey, authToken } =
			this.props;

		return isLoading || searchLoader ? (
			<div style={{ marginLeft: "20px" }}>
				<Spin
					size="large"
					style={{ marginLeft: "480px", marginTop: "130px" }}
				/>
			</div>
		) : (
			<div style={{ overflowX: "auto" }}>
				<Table
					loading={this.state.isLoading}
					rowKey={(record) => record._id}
					columns={this.columns}
					size={"small"}
					dataSource={listGroupData}
					pagination={{
						total: listGroupDataTotal,
						showSizeChanger: true,
						pageSize: this.state.pagination.pageSize,
						current: this.state.pagination.current,
						pageSizeOptions: ["1", "10", "25", "50", "100"],
						onChange: (e) => {
							// e - 1
							//   ? (this.counter = this.tempCounter =
							//       (e - 1) * this.state.pagination.pageSize)
							//   : ((this.counter = 0), (this.tempCounter = 0));
							if (e - 1) {
								this.counter = this.tempCounter =
									(e - 1) * this.state.pagination.pageSize;
								return;
							}

							this.counter = 0;
							this.tempCounter = 0;
						},
					}}
					onChange={this.handleTableChange}
				/>
				{this.state.groupSelectedData ? (
					<EditModal
						refx={(e) => (this.modalRef = e)}
						onClose={this.handleDetailsModalclose.bind(this)}
						groupSelectedData={this.state.groupSelectedData}
						onSubmit={this.editSubmit}
						course_id={course_id}
					/>
				) : null}
				{this.state.groupSelectedData ? (
					<EditGroupPermission
						authToken={authToken}
						refx={(e) => (this.groupPermissionModalRef = e)}
						onClose={this.handleDetailsModalclose.bind(this)}
						groupSelectedData={this.state.groupSelectedData}
						onSubmit={this.assignGroupAdminSubmit}
						course_id={course_id}
					/>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	authToken: state.auth.authToken,
	loginUserData: state.auth.loginUserData,
	listGroupData: state.group.listGroupData,
	listGroupDataTotal: state.group.listGroupDataTotal,
	searchLoader: state.auth.searchLoader,
	searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
	return {
		setLoginFlag: (flag) => {
			dispatch({
				type: "SET_LOGIN_FLAG",
				flag: flag,
			});
		},
		setUserData: (userData) => {
			dispatch({
				type: "SET_USER_DATA",
				userData: userData,
			});
		},
		setUserToken: (authToken) => {
			dispatch({
				type: "SET_USER_AUTHTOKEN",
				authToken: authToken,
			});
		},
		setListGroupData: (listGroupData) => {
			dispatch({
				type: "SET_LIST_GROUP_DATA",
				listGroupData: listGroupData,
			});
		},
		setListGroupDataTotal: (listGroupDataTotal) => {
			dispatch({
				type: "SET_LIST_GROUP_DATA_TOTAL",
				listGroupDataTotal: listGroupDataTotal,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(GroupRecordsTable));
