import { callGetApiToServer } from "../callApi";

export async function getAllCourseSession(authtoken, skip, limit, courseId) {
  console.log(authtoken, limit);
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = "v1/auth/admin/sessions/" + skip + "/" + limit;
    if (courseId) {
      endUrl = "v1/auth/admin/course-sessions/" + skip + "/" + limit + "/" + courseId;
    }

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
