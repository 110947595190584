import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as la from "lodash";
import { Link } from "react-router-dom";

const { Content } = Layout;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {},
    };
  }

  input;

  async componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm(values) {
    const errors: any = {};
    console.log("Validating form -- ", values);
    if (!values.first_name) {
      errors.first_name = "Name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {
    console.log("Basic Value - ", values);

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { loginUserData } = this.props;
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name:
                  loginUserData && loginUserData.first_name
                    ? loginUserData.first_name
                    : null,
              }}
              enableReinitialize
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div className="container-fluid p-20">
                    <div className="form-group">
                      <label className="col-form-label pt-0">
                        <strong>Email :</strong>
                      </label>
                      <div>
                        <label className="col-form-label pt-0">
                          {loginUserData && loginUserData.email
                            ? loginUserData.email
                            : null}
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="formik-field">
                        Name
                        <Input
                          id="first_name"
                          placeholder="Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update Profile
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditProfileForm));
