import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import { AddTestimonialUserImage } from "./AddTestimonialUserImage";
import { TESTIMONIAL_USER_IMAGE_PREVIEW_PATH } from "../../constant/comman";
import { PictureOutlined, DeleteFilled } from "@ant-design/icons";
import MediaItem from "../Media/MediaItem";

const { TextArea } = Input;
const { Content } = Layout;
const Option = Select.Option;

var amenities = [];

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.state = {
      data: [],
      view: false,
      userImage: [],
      isLoading: false,
      visible: false,
    };
  }

  input;
  viewUploadModalRef;
  allTypes = [
    <Option value={"charity"}>Charity</Option>,
    <Option value={"vendor"}>Vendor</Option>,
  ];

  componentDidMount() {
    const { authToken, data } = this.props;

    this.setState({
      isLoading: false,
      userImage: {
        media_name: data.profile_image,
        media_url: data.profile_image_url,
      },
    });
  }

  validateForm(values) {
    const { userImage } = this.state;

    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.first_name) {
      errors.first_name = "First Name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required";
    }

    if (!values.job_post) {
      errors.job_post = "Job Post is required";
    }

    if (!values.title) {
      errors.title = "Testimonial title is required";
    }

    if (!values.review) {
      errors.review = "Review Description is required";
    }

    if (!userImage || userImage.length <= 0) {
      errors.userImage = "Please select user image";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = (values, action) => {
    const { userImage } = this.state;
    const { data } = this.props;

    values.testimonial_id = data._id;
    values.profile_image = userImage.media_name;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  showUploadModal(params) {
    this.setState(
      {
        viewUploadModal: true,
      },
      () => {
        this.viewUploadModalRef.show();
      }
    );
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  uploadMedia = (name, mediaData) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.setState({
        userImage: mediaData,
        viewUploadModal: false,
      });
    } else {
      this.setState({
        viewUploadModal: false,
      });
    }
  };

  render() {
    const { primaryColor, data } = this.props;
    const { userImage } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            first_name: data.first_name,
            last_name: data.last_name,
            job_post: data.job_post,
            title: data.title,
            review: data.review,
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    First Name
                    <Input
                      id="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.first_name && touched.first_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.first_name}
                      </p>
                    ) : null}
                  </div>
                  <div className="formik-field-right">
                    Last Name
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.last_name && touched.last_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.last_name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    User Job Post
                    <Input
                      id="job_post"
                      placeholder="Job Post"
                      value={values.job_post}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.job_post && touched.job_post ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.job_post}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Testimonial Title
                    <Input
                      id="title"
                      placeholder="Testimonial Title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.title && touched.title ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.title}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="formik-field_wrap">
                  <div className="formik-field">
                    Testimonial Review Description
                    <TextArea
                      id="review"
                      placeholder="Testimonial Review Description"
                      value={values.review}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows={5}
                    />
                    {errors.review && touched.review ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.review}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* User Image Section Start */}
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    marginTop: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ float: "left", width: "10%" }}>
                    <PictureOutlined
                      style={{ width: "100%", fontSize: "30px" }}
                    />
                  </div>
                  <Button
                    style={{ width: "90%" }}
                    onClick={this.showUploadModal.bind(this)}
                  >
                    Choose User Image
                  </Button>

                  {errors.userImage ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}
                    >
                      {errors.userImage}
                    </p>
                  ) : null}

                  {this.state.viewUploadModal ? (
                    <AddTestimonialUserImage
                      refx={(e) => (this.viewUploadModalRef = e)}
                      onUpload={this.uploadMedia}
                    />
                  ) : null}
                </div>

                <div
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor:
                      this.selectedColorType !== ""
                        ? this.selectedColorType
                        : "#ffffff",
                    borderRadius: "10px",
                  }}
                >
                  {userImage && userImage.media_name ? (
                    <MediaItem
                      name={userImage.media_name}
                      url={`${TESTIMONIAL_USER_IMAGE_PREVIEW_PATH}${userImage.media_name}`}
                    />
                  ) : null}
                </div>
                {/* User Image Section End */}

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default connect()(withRouter(EditForm));
