// export const WEBURL = "http://173.249.10.82:3332/";
//export const WEBURL = "http://localhost:3001/";
export const WEBURL = "https://trainovate.api.dharmatech.in/";

export const MODULES = [
  { value: "dashboard", label: "Dashboard" },
  { value: "user", label: "User" },
  { value: "category", label: "Category" },
  { value: "sub_category", label: "Sub Category" },
  { value: "language", label: "Language" },
  { value: "testimonial", label: "Testimonial" },
  { value: "course", label: "Course" },
  { value: "group", label: "Group" },
];

export const ROLES = {
  dashboard: ["View"],
  user: ["Add", "Edit", "View", "Delete"],
  category: ["Add", "Edit", "View", "Delete"],
  sub_category: ["Add", "Edit", "View", "Delete"],
  language: ["Add", "Edit", "View", "Delete"],
  testimonial: ["Add", "Edit", "View", "Delete"],
  course: ["Add", "Edit", "View", "Delete", "Preview"],
  group: ["Add", "Edit", "View", "Delete"],
};

export const VIDEO_EXTENSIONS =
  "mpg|mp2|mpeg|mpe|mpv|mp4|m4p|m4v|mkv|flv|vob|svi|ogg|ogv|mov";

export const AUDIO_EXTENSIONS = "mp3|acc|ogg|m4a";

export const IMAGE_EXTENSIONS = "png|jpeg|jpg|gif|webp";

export const COURSE_BANNER_UPLOAD_PATH = WEBURL + "file/upload/course/";

export const COURSE_MEDIA_UPLOAD_PATH = WEBURL + "file/upload/course/";

export const COURSE_INTRO_UPLOAD_PATH = WEBURL + "file/upload/course/";

export const COURSE_PRIMARY_UPLOAD_PATH = WEBURL + "file/upload/course/";

export const COURSE_BENEFIT_UPLOAD_PATH =
  WEBURL + "file/upload/course_benefit/image/";

export const COURSE_BANNER_PREVIEW_PATH = WEBURL + "course/";

export const COURSE_INTRO_PREVIEW_PATH = WEBURL + "course/";

export const COURSE_PRIMARY_PREVIEW_PATH = WEBURL + "course/";

export const COURSE_MEDIA_PREVIEW_PATH = WEBURL + "course/";

export const COURSE_BENEFIT_PREVIEW_PATH = WEBURL + "course/benefit/image/";

export const COURSE_SESSION_MEDIA_UPLOAD_PATH =
  WEBURL + "file/upload/course_session/";

export const COURSE_SESSION_MEDIA_PREVIEW_PATH = WEBURL + "course/session/";

export const COURSE_TOPIC_MEDIA_UPLOAD_PATH =
  WEBURL + "file/upload/course_topic/";

export const COURSE_TOPIC_MEDIA_PREVIEW_PATH = WEBURL + "course/topic/";

export const COURSE_TOPIC_QUESTION_AUDIO_UPLOAD_PATH =
  WEBURL + "file/upload/course_topic_question/audio/";

export const COURSE_TOPIC_QUESTION_VIDEO_UPLOAD_PATH =
  WEBURL + "file/upload/course_topic_question/video/";

export const COURSE_TOPIC_QUESTION_AUDIO_PREVIEW_PATH =
  WEBURL + "course/topic/question/";

export const COURSE_TOPIC_QUESTION_VIDEO_PREVIEW_PATH =
  WEBURL + "course/topic/question/";

export const TESTIMONIAL_USER_IMAGE_UPLOAD_PATH =
  WEBURL + "file/upload/testimonial/";

export const TESTIMONIAL_USER_IMAGE_PREVIEW_PATH = WEBURL + "testimonial/";

export const ACCESS_DENIED_MESSAGE = "Access Denied";

export const ACCESS_DENIED_MESSAGE_DESCRIPTION = "You don't have permission to access this module.";
