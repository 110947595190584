import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, Checkbox, Row } from "antd";
import { Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { MODULES, ROLES } from "../../constant/comman";

const { Content } = Layout;
const Option = Select.Option;

export class AddAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedAdminType: "",
      errors: {
        selectedAdminType: "",
      },
    };
  }

  input;
  permissionsData = {};

  allTypes = [
    <Option value={"subAdmin"} key="subAdmin">
      Sub Admin
    </Option>,
    <Option value={"superAdmin"} key="superAdmin">
      Super Admin
    </Option>,
  ];

  componentDidMount() {
    //set initial permission value start
    this.setInitialPermission();
    //set initial permission value end
  }

  /**
   * set initial permission false for the sub admin user in below format for the defined modules
   * with defined actions in constant
   *
   * FORMAT: dashboard(module_name): { view(role_name): false, create: false, edit: false, delete: false },
   */
  setInitialPermission = (adminType = "subAdmin") => {
    MODULES.map((moduleName) => {
      if (ROLES[moduleName.value]) {
        ROLES[moduleName.value].map(
          (roleName) =>
            (this.permissionsData[moduleName.value] = {
              ...this.permissionsData[moduleName.value],
              [roleName.toLowerCase()]:
                adminType === "superAdmin" ? true : false,
            })
        );
      }
    });
  };

  validateForm = (values) => {
    const { selectedAdminType } = this.state;
    const errors = {};
    console.log("Validating form -- ", values);

    // if (!values.username) {
    //   errors.username = "Username is required";
    // }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = "Please enter a valid email";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password !== "" && values.password.length < 8) {
      errors.password = "At least 8 characters required";
    }

    if (values.password !== values.confirm_password) {
      errors.confirm_password = "Password and Confirm password does not match.";
    }

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }

    if (!selectedAdminType) {
      errors.selectedAdminType = "Please select admin type";
    }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  handleSubmit = async (values, action) => {
    const { selectedAdminType } = this.state;

    values.role = selectedAdminType;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  changeAdminType = (value) => {
    this.setState({
      selectedAdminType: value,
    });
    this.setInitialPermission(value);
  };

  render() {
    const { primaryColor } = this.props;
    const { subAdminType } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Admin"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                // username: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                confirm_password: "",
                role: "",
                permissions: this.permissionsData,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      {/* <div className="formik-field-left">
                        Username
                        <Input
                          id="username"
                          placeholder="Username"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.username && touched.username ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.username}
                          </p>
                        ) : null}
                      </div> */}

                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Phone
                        <Input
                          id="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Password
                        <Input
                          type="password"
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Confirm Password
                        <Input
                          id="confirm_password"
                          type="password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_password && touched.confirm_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.confirm_password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Admin Type
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeAdminType(value)}
                          placeholder="Select Admin Type"
                          showSearch
                          // defaultValue="subAdmin"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allTypes}
                        </Select>
                        {errors.selectedAdminType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.selectedAdminType}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* {this.state.selectedAdminType === "subAdmin" &&
                      MODULES.map((moduleName, moduleIndex) => (
                        <div key={moduleIndex}>
                          <div className="col-md-12">
                            <div className="row">
                              <label>
                                <strong>{moduleName.label}</strong>
                              </label>
                            </div>
                            <div className="row">
                              <div className="formik-field">
                                {ROLES[moduleName.value].map(
                                  (roleName, roleIndex) => (
                                    <span key={roleIndex}>
                                      <Checkbox
                                        onChange={handleChange}
                                        checked={
                                          values.permissions[moduleName.value][
                                            roleName.toLowerCase()
                                          ]
                                        }
                                        name={`permissions.${
                                          moduleName.value
                                        }.${roleName.toLowerCase()}`}
                                      >
                                        {roleName}
                                      </Checkbox>
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <Divider></Divider>
                        </div>
                      ))} */}

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(AddAdmin));
