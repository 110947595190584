import { callGetApiToServer } from '../callApi';

export async function listSubCategoryFromCategory(
  authtoken,
  categoryId,
  skip,
  limit
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    }

    let endUrl = "v1/auth/filter-sub-category/" + categoryId + "/" + skip + "/" + limit;
    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
