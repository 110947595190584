import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllTestimonial } from "../../Graphs/Testimonial/listTestimonial";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import TestimonialRecordsTable from "./TestimonialRecordsTable";
import { AddTestimonial } from "./AddTestimonial";
import { addTestimonial } from "../../Graphs/Testimonial/addTestimonial";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialList: [],
      testimonialTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(
        this.props.loginUserData.permissions,
        "testimonial",
        "view"
      )
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("TESTIMONIAL");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listTestimonialData();
    } catch (e) {
      notification["success"]({
        message: "Testimonial",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listTestimonialData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListTestimonialData,
      setListTestimonialDataTotal,
    } = this.props;

    try {
      let testimonialData = await getAllTestimonial(authToken, 0, 10);

      if (testimonialData.status === 200) {
        //set testimonial data
        // await setListTestimonialData(testimonialData.data.docs);
        await setListTestimonialData(testimonialData.data);
        await setListTestimonialDataTotal(testimonialData.total);

        this.setState({
          testimonialList: testimonialData.data,
          // testimonialList: testimonialData.data.docs,
          testimonialTotal: testimonialData.total,
        });
      } else if (testimonialData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addTestimonialData = await addTestimonial(authToken, values);
      if (addTestimonialData.status === 200) {
        //set testimonial data
        await this.listTestimonialData();
      } else if (addTestimonialData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { testimonialList, testimonialTotal, loginUserData } = this.props;

    return (
      <Fragment>
        <Title parent="Testimonial" title="testimonial" />

        {checkPermission(
          loginUserData.permissions,
          "testimonial",
          "create"
        ) && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddTestimonial
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <TestimonialRecordsTable
                listTestimonialData={testimonialList}
                primaryColor={primaryColor}
                listTestimonialDataTotal={testimonialTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  testimonialList: state.testimonial.listTestimonialData,
  testimonialTotal: state.testimonial.listTestimonialDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListTestimonialData: (listTestimonialData) => {
      dispatch({
        type: "SET_LIST_TESTIMONIAL_DATA",
        listTestimonialData: listTestimonialData,
      });
    },
    setListTestimonialDataTotal: (listTestimonialDataTotal) => {
      dispatch({
        type: "SET_LIST_TESTIMONIAL_DATA_TOTAL",
        listTestimonialDataTotal: listTestimonialDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Testimonial));
