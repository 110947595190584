import * as React from "react";
import { PaperClipOutlined, SoundOutlined } from "@ant-design/icons";
import { VIDEO_EXTENSIONS, AUDIO_EXTENSIONS, IMAGE_EXTENSIONS } from "../../constant/comman";

export default function MediaItem(props) {
	const { name, type, thumbMediaUrl, url, width } = props;

	const videoRegx = new RegExp(`.(${VIDEO_EXTENSIONS})`)
	const audioRegx = new RegExp(`.(${AUDIO_EXTENSIONS})`)
	const imageRegx = new RegExp(`.(${IMAGE_EXTENSIONS})`)

	return (
		<>
			<div className="ant-upload-list-item-info row align-items-center mb-3">
				{videoRegx.test(name) && (
					<a href={url} target="_blank" className="ant-upload-text-icon">
						<img src={thumbMediaUrl} width={width ? width : 50} />
					</a>
				) || audioRegx.test(name) && (
					<a href={url} target="_blank" className="ant-upload-text-icon">
						<p className="ant-upload-drag-icon">
							<SoundOutlined />
						</p>
					</a>
				) || imageRegx.test(name) && (
					<a href={url} target="_blank" className="ant-upload-text-icon">
						<img src={url} width={width ? width : 50} />
					</a>
				) || (
						<p className="ant-upload-drag-icon">
							<PaperClipOutlined />
						</p>
					)
				}
				{name && (
					<div>
						<span className="ant-upload-list-item-name e-file-name">{name}</span>
					</div>
				)}
			</div>
		</>
	);
}