import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllAppContents } from "../../Graphs/AppContent/listAppContent";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteAppContent } from "../../Graphs/AppContent/deleteAppContent";
import { changeStatus } from "../../Graphs/AppContent/changeStatus";
import { editAppContent } from "../../Graphs/AppContent/editAppContent";
import { searchAppContent } from "../../Graphs/AppContent/searchAppContent";
import { EditModal } from "./EditModal";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_appContent_media/";

class AppContentRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedAppContents = this.getLimitedAppContents.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      appContentSelectedData: null,
    });
  }

  modalRef;

  async getLimitedAppContents(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchAppContentData(start, end);
        } else {
          //call normal appContent Data
          this.listAppContentData(start, end);
        }
      }
    );
  }

  listSearchAppContentData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        setListAppContentData,
        setListAppContentDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let appContentData = await searchAppContent(
        authToken,
        searchText,
        start,
        end
      );

      if (appContentData.status == 200) {
        //set appContent Data
        await setListAppContentData(appContentData.data);
        await setListAppContentDataTotal(appContentData.total);

        this.setState({
          isLoading: false,
          data: appContentData.data,
        });
      } else if (appContentData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search appContent Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listAppContentData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        setListAppContentData,
        setListAppContentDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let appContentData = await getAllAppContents(authToken, start, end);

      if (appContentData.status == 200) {
        //set appContent Data
        await setListAppContentData(appContentData.data);
        await setListAppContentDataTotal(appContentData.total);

        this.setState({
          isLoading: false,
          data: appContentData.data,
        });
      } else if (appContentData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list appContent Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        appContentSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      appContentSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        console.log("appContent record --> ", item);
        return (
          <div>
            <Tooltip placement="bottom" title="Name">
              <div>{item.name}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Slug",
      key: "slug_name",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Slug">
              <div>{item.slug_name}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Active",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="AppContent Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginLeft: "10px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteAppContent.bind(this, item)}
                  title="Are you sure you want to delete static page"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteAppContent(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        searchText,
        setListAppContentData,
        setListAppContentDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteAppContent(
        authToken,
        item._id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete AppContent",
          description: "Successfully deleted",
        });
        if (searchText != null && searchText != "") {
          this.listSearchAppContentData(startIndex, limitNumber);
          return;
        }
        this.listAppContentData(startIndex, limitNumber);
        await setListAppContentData(deleteData.data);
        await setListAppContentDataTotal(deleteData.total);
        //set appContent Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!delete appContent ", e);
      notification["error"]({
        message: "Delete AppContent",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change AppContent Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchAppContentData(startIndex, limitNumber);
          return;
        }
        this.listAppContentData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (err) {
      console.log("!!!!!!!!!!!!!error printed here", err);
      notification["error"]({
        message: "Change AppContent Status",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (appContentObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editAppContent(
        authToken,
        appContentObject
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit AppContent Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchAppContentData(startIndex, limitNumber);
          return;
        }
        this.listAppContentData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!!error in editSubmit function", e);
      notification["error"]({
        message: "Edit AppContent",
        description: e,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iappContents", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedAppContents(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const {
      appContentTotal,
      listAppContentData,
      listAppContentDataTotal,
      searchLoader,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          dataSource={listAppContentData}
          pagination={{
            total: listAppContentDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.appContentSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            appContentSelectedData={this.state.appContentSelectedData}
            onSubmit={this.editSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listAppContentData: state.appcontent.listAppContentData,
  listAppContentDataTotal: state.appcontent.listAppContentDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setAppContentData: (appContentData) => {
      dispatch({
        type: "SET_APPCONTENT_DATA",
        appContentData: appContentData,
      });
    },
    setAppContentToken: (authToken) => {
      dispatch({
        type: "SET_APPCONTENT_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListAppContentData: (listAppContentData) => {
      dispatch({
        type: "SET_LIST_APPCONTENT_DATA",
        listAppContentData: listAppContentData,
      });
    },
    setListAppContentDataTotal: (listAppContentDataTotal) => {
      dispatch({
        type: "SET_LIST_APPCONTENT_DATA_TOTAL",
        listAppContentDataTotal: listAppContentDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppContentRecordsTable));
