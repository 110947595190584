import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { listUserGroup } from "../../Graphs/Group/listUserGroup";
import { listCourseGroup } from "../../Graphs/Group/listCourseGroup";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import GroupRecordsTable from "./GroupRecordsTable";
import { AddGroup } from "./AddGroup";
import { addGroup } from "../../Graphs/Group/addGroup";
import { Tabs } from "antd";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

const { TabPane } = Tabs;

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupList: [],
      groupTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
      activeTabKey: "1",
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(this.props.loginUserData.permissions, "group", "view")
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText, course_id } = this.props;

      let editPrimaryColor = await localStorage.getItem("primary_color");

      let routeName = "GROUP";

      if (course_id) {
        routeName = "COURSE_GROUP";
      }

      await setRouteName(routeName);

      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listGroupData();
    } catch (e) {
      notification["success"]({
        message: "Group",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listGroupData = async (key) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListGroupData,
      setListGroupDataTotal,
      course_id,
    } = this.props;

    try {
      let groupData = [];

      if (this.state.activeTabKey === "1") {
        groupData = await listCourseGroup(authToken, 0, 10, course_id);
      } else {
        groupData = await listUserGroup(authToken, 0, 10);
      }

      if (groupData.status === 200) {
        //set group data
        await setListGroupData(groupData.data);
        await setListGroupDataTotal(groupData.total);

        this.setState({
          groupList: groupData.data,
          groupTotal: groupData.total,
        });
      } else if (groupData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addGroupData = await addGroup(authToken, values);
      if (addGroupData.status === 200) {
        //set group data
        await this.listGroupData();
      } else if (addGroupData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  changeTab = async (key) => {
    this.setState(
      {
        activeTabKey: key,
        isLoading: true,
      },
      () => {
        this.listGroupData();
      }
    );
  };

  groupRecordsTableComponent = () => {
    const { primaryColor, activeTabKey } = this.state;
    const { listGroupData, listGroupDataTotal, course_id } = this.props;
    return (
      <GroupRecordsTable
        listGroupData={listGroupData}
        primaryColor={primaryColor}
        listGroupDataTotal={listGroupDataTotal}
        course_id={course_id}
        activeTabKey={activeTabKey}
      />
    );
  };

  render() {
    const { primaryColor, isLoading, activeTabKey } = this.state;
    const { course_id } = this.props;

    return (
      <Fragment>
        {!course_id ? <Title parent="Group" title="group" /> : null}
        {/*<div*/}
        {/*	style={{*/}
        {/*		marginTop: "20px",*/}
        {/*		display: "inline-block",*/}
        {/*		width: "100%",*/}
        {/*		marginBottom: "20px",*/}
        {/*		paddingLeft: "14px",*/}
        {/*		paddingRight: "55px",*/}
        {/*	}}*/}
        {/*>*/}
        {/*	<div style={{ float: "left", cursor: "pointer" }}>*/}
        {/*		<AddGroup*/}
        {/*			primaryColor={primaryColor}*/}
        {/*			course_id={course_id}*/}
        {/*			onSubmit={this.addSubmit}*/}
        {/*		/>*/}
        {/*	</div>*/}
        {/*</div>*/}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              {course_id ? (
                this.groupRecordsTableComponent()
              ) : (
                <Tabs
                  activeKey={activeTabKey}
                  onChange={(key) => this.changeTab(key)}
                >
                  <TabPane tab="Course Groups" key="1">
                    {this.groupRecordsTableComponent()}
                  </TabPane>
                  <TabPane tab="User Groups" key="2">
                    {this.groupRecordsTableComponent()}
                  </TabPane>
                </Tabs>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listGroupData: state.group.listGroupData,
  listGroupDataTotal: state.group.listGroupDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListGroupData: (listGroupData) => {
      dispatch({
        type: "SET_LIST_GROUP_DATA",
        listGroupData: listGroupData,
      });
    },
    setListGroupDataTotal: (listGroupDataTotal) => {
      dispatch({
        type: "SET_LIST_GROUP_DATA_TOTAL",
        listGroupDataTotal: listGroupDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Group));
