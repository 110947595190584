import { SET_LIST_LANGUAGE_DATA, SET_LIST_LANGUAGE_DATA_TOTAL } from "../constant/actionTypes";
import ConfigDB from '../data/customizer/config';

const initial_state = {
    listLanguageData : null,
    listLanguageDataTotal: 0,
};

export default (state = initial_state, action) => {
    console.log("ac",action)
    switch (action.type) {

        case SET_LIST_LANGUAGE_DATA:
            return { ...state, loading: false, listLanguageData: action.listLanguageData };

        case SET_LIST_LANGUAGE_DATA_TOTAL:
            return { ...state, loading: false, listLanguageDataTotal: action.listLanguageDataTotal };
            
        default: return { ...state };
    }
}
