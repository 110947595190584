import { callApiToServer } from '../callApi';

export async function changePassword(
  authtoken,
  formData,
  itemId
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    console.log(formData)

    let body = JSON.stringify({
      oldPassword: formData.old_password,
      newPassword: formData.new_password,
      user_id: itemId,
    });

    let endUrl = "v1/auth/user/change-password-admin";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    console.log("----- API response -----", responseData)

    return responseData;

  } catch (err) {
    throw err;
  }
}
