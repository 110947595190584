import * as React from "react";
import { Button, Modal, Select, Spin } from "antd";
import { Formik } from "formik";
import { dropDownListAllGroupUsers } from "../../Graphs/Group/dropDownListAllGroupUsers";

const Option = Select.Option;

export class EditGroupPermission extends React.Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			visible: false,
			data: [],
			recordDetails: {},
			isLoading: false, // Fixme this should be true
			closable: true,
			selectedAdminUserId: []
		};
	}

	allAdminUsers = [];

	async componentDidMount() {

		if (this.props.refx) {
			this.props.refx(this);
		}

		const { authToken, groupSelectedData } = this.props;

		let adminUserData = await dropDownListAllGroupUsers(authToken, groupSelectedData._id, 0, 1000000);

		if (adminUserData.data && adminUserData.data.length >= 1) {
			adminUserData.data.map((data, index) => {
				this.allAdminUsers.push(
					<Option value={data.userData[0]['_id']} key={data.userData[0]['_id']}>
						{data.userData && data.userData[0] && data.userData[0]['first_name'] ? data.userData[0]['first_name'] + " " : ""}
						{data.userData && data.userData[0] && data.userData[0]['last_name'] ? data.userData[0]['last_name'] : ""}
					</Option>
				);
			});
		}

		this.setState({
			selectedAdminUserId: groupSelectedData.admins ? groupSelectedData.admins.map((item) => item._id) : [],
		});

	}

	handleOk = (e) => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = (e) => {
		this.hide();
	};

	show() {
		this.setState({
			visible: true,
		});
	}

	hide() {
		this.setState({
			visible: false,
		});
		this.props.onClose();
	}


	validateForm = (values) => {
		const { selectedAdminUserId } = this.state;

		const errors = {};

		console.log("Validating form -- ", values);

		if (!selectedAdminUserId) {
			errors.selectedAdminUserId = "Please select User";
		}

		console.log("Validating errors -- ", errors);
		return errors;
	};

	handleSubmit = (values, action) => {
		const { selectedAdminUserId } = this.state;
		const { groupSelectedData } = this.props;

		values.group_id = groupSelectedData._id;
		values.admins = selectedAdminUserId.map((item) => ({
			user_id: item,
		}));

		this.props.onSubmit(values);

		this.setState({
			visible: false,
		});
	}

	changeAdminUserValue = (value) => {
		this.setState({
			selectedAdminUserId: value,
		});
	};

	render() {
		const { admin_user_id } = this.props;

		const { selectedAdminUserId } = this.state;

		return (
			// <StyleBox>
			<Modal
				width="600px"
				closable={this.state.closable}
				visible={this.state.visible}
				title="Edit Group"
				onOk={this.handleOk}
				onCancel={this.handleCancel}
				footer={null}
				destroyOnClose={true}
			>
				{this.state.isLoading ? (
					<div style={{ justifyContent: "center" }}>
						<Spin/>
					</div>
				) : (
					<Formik
						initialValues={{
							admin_id: "",
						}}
						validate={this.validateForm}
						onSubmit={this.handleSubmit}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  setFieldValue,
							  setFieldTouched,
							  isSubmitting,
						  }) => {
							return (
								<div>
									<div className="formik-field_wrap">
										<div className="formik-field">
											Admin User
											<Select
												allowClear
												mode="multiple"
												style={{ width: "100%" }}
												onChange={(value) => this.changeAdminUserValue(value)}
												placeholder="Select User"
												showSearch
												value={selectedAdminUserId}
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.allAdminUsers}
											</Select>
											{errors.selectedAdminUserId ? (
												<p
													style={{
														color: "red",
														fontSize: "small",
														margin: "0",
													}}
												>
													{errors.selectedAdminUserId}
												</p>
											) : null}
										</div>
									</div>

									<Button
										type="primary"
										style={{
											marginTop: "19px",
											background: "#5C6AC4",
											color: "#fff",
										}}
										onClick={handleSubmit}
									>
										Edit
									</Button>
								</div>
							);
						}}
					</Formik>
				)}
			</Modal>
		);
	}
}
