import { callApiToServer } from "../callApi";

export async function deleteTestimonial(authtoken, testimonialId) {
  console.log(testimonialId);
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      testimonial_id: testimonialId,
    });

    let endUrl = "v1/auth/admin/delete-testimonial";

    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "DELETE", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
