import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, DatePicker } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { addUser } from "../../Graphs/User/addUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const { Content } = Layout;
const Option = Select.Option;

export class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedType: "",
      isVerified: false,
      selectedDob: "",
      errors: {
        email: ""
      },
    };
  }

  input;


  validateForm = (values) => {
    const { selectedDob } = this.state;
    const errors = {};
    console.log("Validating form -- ", values);
    console.log("!!!!", selectedDob);

    // if (!values.username) {
    //   errors.username = "Username is required";
    // }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = "Please enter a valid email";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password !== '' && values.password.length < 8) {
      errors.password = "At least 8 characters required";
    }

    if (values.password !== values.confirm_password) {
      errors.confirm_password = "Password and Confirm password does not match.";
    }

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }

    if (!selectedDob) {
      errors.selectedDob = "Please select Date of Birth";
    }

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    const { selectedDob } = this.state;
    values.dob = selectedDob;
    this.props.onSubmit(values);
    this.setState({
      visible: false,
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  changeDob = (date, dateString) => {
    this.setState({
      selectedDob: dateString,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add User"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                // username: "",
                email: "",
                phone: "",
                country: "",
                city: "",
                bio: "",
                current_location: "",
                first_name: "",
                last_name: "",
                address_line1: "",
                address_line2: "",
                is_verified: false,
                job_post: "",
                password: "",
                confirm_password: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    {/* <div className="formik-field_wrap"> */}
                    {/* <div className="formik-field-left">
                        Username
                        <Input
                          id="username"
                          placeholder="Username"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.username && touched.username ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.username}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Name
                        <Input
                          id="name"
                          placeholder="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                    </div> */}


                    <div className="formik-field_wrap" >
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap" >
                      <div className="formik-field-left">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Phone
                        <Input
                          id="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Password
                        <Input
                          id="password"
                          type="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0"
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Confirm Password
                        <Input
                          id="confirm_password"
                          type="password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_password && touched.confirm_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0"
                            }}
                          >
                            {errors.confirm_password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Date of Birth
                        <DatePicker
                          onChange={(date, dateString) => this.changeDob(date, dateString)}
                        />
                        {errors.selectedDob ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0"
                            }}
                          >
                            {errors.selectedDob}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Current Location
                        <Input
                          id="current_location"
                          placeholder="Current Location"
                          value={values.current_location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.current_location && touched.current_location ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.current_location}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap" >
                      {/* <div className="formik-field-left">
                        Password
                        <Input
                          type="password"
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div> */}

                      <div className="formik-field-left">
                        Address line 1
                        <Input
                          id="address_line1"
                          placeholder="address line 1"
                          value={values.address_line1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address_line1 && touched.address_line1 ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.address_line1}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Address line 2
                        <Input
                          id="address_line2"
                          placeholder="address line 2"
                          value={values.address_line2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address_line2 && touched.address_line2 ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.address_line2}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap" >
                      <div className="formik-field-left">
                        city
                        <Input
                          id="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.city}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Country
                        <Input
                          id="country"
                          placeholder="Country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.country && touched.country ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.country}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap" >
                      <div className="formik-field-left">
                        Bio
                        <Input
                          type='textbox'
                          id="bio"
                          placeholder="Bio"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.bio && touched.bio ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.bio}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="formik-field_wrap" >
                      <div className="formik-field-left">
                        Job Post
                        <Input
                          id="job_post"
                          placeholder="Job Post"
                          value={values.job_post}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.job_post && touched.job_post ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.job_post}
                          </p>
                        ) : null}
                      </div> */}

                    {/* <div className="formik-field-right">
                        <Checkbox required checked={values.is_verified} name="is_verified" onChange={handleChange}>Is Verified ?</Checkbox>
                        {this.state.errors.is_verified ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0"
                            }}
                          >
                            {this.state.errors.is_verified}
                          </p>
                        ) : null}
                      </div> */}
                    {/* </div> */}

                    {/* <div className="formik-field_wrap" > */}
                    {/* <div className="formik-field-left">
                        Is Verified ?
                        <Input
                          type="checkbox"
                          id="is_verified"
                          placeholder="name"
                          value={values.is_verified}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div> */}


                    {/* </div> */}

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}



const mapStateToProps = (state) => ({
  authToken: state.auth.authToken
});



export default connect(
  mapStateToProps
)(withRouter(AddUser));