import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Users, Grid, Copy, Globe, FileText } from "react-feather";
import { callAllKpis } from "../../../Graphs/Dashboard/callKpis";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { checkPermission } from "../../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../../constant/comman";
import { notification } from "antd";

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCount: 0,
      categoryCount: 0,
      languageCount: 0,
      courseCount: 0,
      sessionCount: 0,
      topicCount: 0,
      isLoading: false,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(
        this.props.loginUserData.permissions,
        "dashboard",
        "view"
      )
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/edit-profile");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      await setRouteName("DASHBOARD");
      let responseKpiData = await callAllKpis(authToken);
      console.log(responseKpiData.data);
      if (responseKpiData.status === 200) {
        //set kpi data
        this.setState({
          userCount: responseKpiData.data.userCount,
          categoryCount: responseKpiData.data.categoryCount,
          languageCount: responseKpiData.data.languageCount,
          courseCount: responseKpiData.data.courseCount,
          sessionCount: responseKpiData.data.sessionCount,
          topicCount: responseKpiData.data.topicCount,
        });
      } else if (responseKpiData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const {
      userCount,
      categoryCount,
      languageCount,
      courseCount,
      sessionCount,
      topicCount,
    } = this.state;

    return (
      <Fragment>
        <Breadcrumb parent="Dashboard" title="Dashboard" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/user">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Users />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Users</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={userCount} />
                        </h4>
                        <Users className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/category">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Grid />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Categories</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={categoryCount} />
                        </h4>
                        <Grid className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/sub-category">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Copy />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Sub Categories</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={categoryCount} />
                        </h4>
                        <Copy className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/language">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Globe />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Languages</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={languageCount} />
                        </h4>
                        <Globe className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/courses">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <FileText />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Courses</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={courseCount} />
                        </h4>
                        <FileText className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/module">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <FileText />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Modules</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={topicCount} />
                        </h4>
                        <FileText className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/course-session">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <FileText />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Sessions</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={sessionCount} />
                        </h4>
                        <FileText className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <Link to="/course-session-topic">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <FileText />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Topics</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={topicCount} />
                        </h4>
                        <FileText className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Default));
