import { callApiToServer } from '../callApi';

export async function editAdminPassword(
  authtoken,
  adminObject
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken,
    };

    console.log(adminObject)

    let body = JSON.stringify({
      old_password: adminObject.old_password,
      password: adminObject.new_password
    });

    let endUrl = "v1/auth/change-admin-password";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    console.log("----- API response -----", responseData)

    return responseData;

  } catch (err) {
    throw err;
  }
}
