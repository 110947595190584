import { callApiToServer } from "../callApi";

export async function editCategory(authtoken, categoryObject, categoryId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      "category_id": categoryId,
      "name": categoryObject.name
    });

    console.log(body);

    let endUrl = "v1/auth/admin/edit-category";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
