import {
  SET_LIST_COURSE_DATA,
  SET_LIST_COURSE_DATA_TOTAL,
  SET_SINGLE_COURSE_DATA,
} from "../constant/actionTypes";

const initial_state = {
  listCourseData: null,
  listCourseDataTotal: 0,
  singleCourseData: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_COURSE_DATA:
      return {
        ...state,
        loading: false,
        listCourseData: action.listCourseData,
      };

    case SET_LIST_COURSE_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listCourseDataTotal: action.listCourseDataTotal,
      };

    case SET_SINGLE_COURSE_DATA:
      return {
        ...state,
        loading: false,
        singleCourseData: action.singleCourseData,
      };

    default:
      return { ...state };
  }
};
