import { Modal, Button, Divider, Checkbox } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik } from "formik";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			data: [],
			view: false,
			isLoading: false,
			visible: false,
			selectedCourseType: "",
			isRestricted: false,
			isPrivate: false,
			errors: {
				selectedCourseType: "",
			},
		};
	}

	input;
	allCourses = [];

	async componentDidMount() {
		const { authToken, data, course_id } = this.props;

		let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

		if (courseData.data && courseData.data.length >= 1) {
			courseData.data.map((data, index) => {
				this.allCourses.push(
					<Option value={data._id} key={data._id}>
						{data.name}
					</Option>
				);
			});
		}

		// this.setState({
		//   selectedCourseType: this.props.data.item.courseData && this.props.data.item.courseData[0] && this.props.data.item.courseData[0]._id ? this.props.data.item.courseData[0]._id : "",
		// });
		this.setState({
			selectedCourseType:
				data.courseData && data.courseData[0] && data.courseData[0]._id
					? data.courseData[0]._id
					: "",
		});
	}

	validateForm = (values) => {
		const { selectedCourseType } = this.state;

		const errors = {};

		console.log("Validating form -- ", values);

		if (!values.name) {
			errors.name = "Name is required";
		}

		if (!selectedCourseType) {
			errors.selectedCourseType = "Please select course";
		}

		console.log("Validating errors -- ", errors);
		return errors;
	};

	handleSubmit(values, action) {
		const { selectedCourseType } = this.state;
		const { data } = this.props;

		values.topic_id = data._id;
		values.course_id = selectedCourseType;

		this.props.onSubmit(values);

		this.setState({
			visible: false,
		});
	}

	changeCourse = (value) => {
		this.setState({
			selectedCourseType: value,
		});
	};

	onChangeIsRestricted() {
		this.setState({
			isRestricted: !this.state.isRestricted,
		});
	}

	onChangeIsPrivate() {
		this.setState({
			isPrivate: !this.state.isPrivate,
		});
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleCancel = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	render() {
		const { primaryColor, course_id } = this.props;
		const { selectedCourseType, isPrivate, isRestricted } = this.state;

		return (
			<div>
				<Formik
					initialValues={{
						name: this.props.data.name,
					}}
					validate={this.validateForm}
					onSubmit={this.handleSubmit}
				>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  setFieldValue,
						  setFieldTouched,
						  isSubmitting,
					  }) => {
						return (
							<div>
								<div className="formik-field_wrap">
									<div className="formik-field-left">
										Name
										<Input
											id="name"
											placeholder="Name"
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.name && touched.name ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{errors.name}
											</p>
										) : null}
									</div>

									{/* <div className="formik-field-right">
                    Sort Number
                    <Input
                      id="sort_number"
                      placeholder="Sort Number"
                      value={values.sort_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sort_number && touched.sort_number ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.sort_number}
                      </p>
                    ) : null}
                  </div> */}

									<div className="formik-field-right">
										Course
										<Select
											allowClear
											style={{ width: "100%" }}
											onChange={(value) => this.changeCourse(value)}
											placeholder="Select Course"
											showSearch
											disabled={course_id ? true : false}
											value={selectedCourseType}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
										>
											{this.allCourses}
										</Select>
										{errors.selectedCourseType ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{errors.selectedCourseType}
											</p>
										) : null}
									</div>
								</div>

								<div className="formik-field_wrap">
									<div className="formik-field-left">
										<Checkbox
											onChange={(value) => this.onChangeIsRestricted()}
											checked={isRestricted}
										>
											is Restricted?
										</Checkbox>
										{this.state.errors.isRestricted ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{this.state.errors.isRestricted}
											</p>
										) : null}
									</div>

									<div className="formik-field-right">
										<Checkbox
											onChange={(value) => this.onChangeIsPrivate()}
											checked={isPrivate}
										>
											is Private?
										</Checkbox>
										{this.state.errors.isPrivate ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{this.state.errors.isPrivate}
											</p>
										) : null}
									</div>
								</div>

								<Button
									type="primary"
									style={{
										marginTop: "19px",
										background: "#5C6AC4",
										color: "#fff",
									}}
									onClick={handleSubmit}
								>
									Edit
								</Button>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(EditForm));
