import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUser } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button, Table } from "antd";
import Title from "./Title";
import CourseRecordsTable from "./CourseRecordsTable";
import { AddCourse } from "./AddCourse";
import { addCourse } from "../../Graphs/Course/addCourse";
import { getAllCourse } from "../../Graphs/Course/listCourse";
import { searchCourse } from "../../Graphs/Course/searchCourse";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(this.props.loginUserData.permissions, "course", "view")
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("COURSE");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCourseData();
    } catch (e) {
      notification["success"]({
        message: "Course",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCourseData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCourseData,
      setListCourseDataTotal,
    } = this.props;

    try {
      let courseData = await getAllCourse(authToken, 0, 10);

      if (courseData.status === 200) {
        //set user data
        await setListCourseData(courseData.data);
        // await setListCourseData(courseData.data.docs);
        await setListCourseDataTotal(courseData.total);

        this.setState({
          courseList: courseData.data,
          // courseList: courseData.data.docs,
          courseListTotal: courseData.total,
        });
      } else if (courseData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCourseData,
      setListCourseDataTotal,
    } = this.props;

    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      console.log(values);
      let addCourseData = await addCourse(authToken, values);

      if (addCourseData.status === 200) {
        await this.listCourseData();
        notification["success"]({
          message: "Add Course Status",
          description: "Course added successfully",
        });
      } else if (addCourseData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Add Course Status",
        description: "There was a problem in adding Course",
      });
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listCourseData, listCourseDataTotal, loginUserData, authToken } =
      this.props;

    return (
      <Fragment>
        <Title parent="Course" title="Course" />

        {checkPermission(loginUserData.permissions, "course", "create") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddCourse
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                authToken={authToken}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CourseRecordsTable
                courseList={listCourseData}
                courseTotal={listCourseDataTotal}
                primaryColor={primaryColor}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listCourseData: state.course.listCourseData,
  listCourseDataTotal: state.course.listCourseDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCourseData: (listCourseData) => {
      dispatch({
        type: "SET_LIST_COURSE_DATA",
        listCourseData: listCourseData,
      });
    },
    setListCourseDataTotal: (listCourseDataTotal) => {
      dispatch({
        type: "SET_LIST_COURSE_DATA_TOTAL",
        listCourseDataTotal: listCourseDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Course));
