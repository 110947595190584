import React, { Fragment, Component } from "react";
import Title from "./Title";
import { getAllAppContents } from "../../Graphs/AppContent/listAppContent";
import { createAppContent } from "../../Graphs/AppContent/createAppContent";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import AppContentRecordsTable from "./AppContentRecordsTable";
import { AddAppContentModal } from "./AddAppContentModal";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class AppContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appContentList: [],
      appContentTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (this.props.loginUserData.role !== "SUPERADMIN") {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        setListAppContentData,
        setListAppContentDataTotal,
        setRouteName,
        setSearchText,
        authUserType,
      } = this.props;

      let editPrimaryColor = await localStorage.getItem("primary_color");
      console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

      await setRouteName("APPCONTENT");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      let appContentData = await getAllAppContents(authToken, 0, 10);

      if (appContentData.status == 200) {
        //set appContent data
        await setListAppContentData(appContentData.data);
        await setListAppContentDataTotal(appContentData.total);

        this.setState({
          appContentList: appContentData.data,
          appContentTotal: appContentData.total,
        });
      } else if (appContentData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      await createAppContent(authToken, values);
      await this.listAppContentData();
    } catch (e) {
      notification["error"]({
        message: "Create App Content",
        description: e,
      });
    }
  };

  listAppContentData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setAppContentData,
        setAppContentToken,
        setListAppContentData,
        setListAppContentDataTotal,
      } = this.props;
      let appContentData = await getAllAppContents(authToken, 0, 10);

      if (appContentData.status == 200) {
        //set appContent data
        await setListAppContentData(appContentData.data);
        await setListAppContentDataTotal(appContentData.total);

        this.setState({
          appContentList: appContentData.data,
          appContentTotal: appContentData.total,
        });
      } else if (appContentData.status == 401) {
        await setLoginFlag(false);
        await setAppContentData(null);
        await setAppContentToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }

      return appContentData;
    } catch (e) {
      console.log("!!!!!!!!!error in listAppContentData", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, appContentList, isLoading, appContentTotal } =
      this.state;

    return (
      <Fragment>
        <Title parent="Static Pages" title="Static Pages" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddAppContentModal
              refx={(e) => (this.modalRef = e)}
              primaryColor={primaryColor}
              onSubmit={this.addSubmit}
              authToken={this.props.authToken}
            />
          </div>
        </div>
        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <AppContentRecordsTable
                data={appContentList}
                primaryColor={primaryColor}
                appContentTotal={appContentTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  authUserType: state.auth.authUserType,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setAppContentData: (appContentData) => {
      dispatch({
        type: "SET_APPCONTENT_DATA",
        appContentData: appContentData,
      });
    },

    setAppContentToken: (authToken) => {
      dispatch({
        type: "SET_APPCONTENT_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListAppContentData: (listAppContentData) => {
      dispatch({
        type: "SET_LIST_APPCONTENT_DATA",
        listAppContentData: listAppContentData,
      });
    },
    setListAppContentDataTotal: (listAppContentDataTotal) => {
      dispatch({
        type: "SET_LIST_APPCONTENT_DATA_TOTAL",
        listAppContentDataTotal: listAppContentDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppContent));
