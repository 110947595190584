import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllTestimonial } from "../../Graphs/Testimonial/listTestimonial";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteTestimonial } from "../../Graphs/Testimonial/deleteTestimonial";
import { changeStatus } from "../../Graphs/Testimonial/changeStatus";
import { editTestimonial } from "../../Graphs/Testimonial/editTestimonial";
import { searchTestimonial } from "../../Graphs/Testimonial/searchTestimonial";
import { EditModal } from "./EditModal";
import { checkPermission } from "../AdminUser/CheckPremission";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class TestimonialRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedTestimonial = this.getLimitedTestimonial.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      testimonialSelectedData: {},
    });
  }

  modalRef;

  async getLimitedTestimonial(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchTestimonialData(start, end);
        } else {
          //call normal user Data
          this.listTestimonialData(start, end);
        }
      }
    );
  }

  listSearchTestimonialData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTestimonialData,
        setListTestimonialDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let testimonialData = await searchTestimonial(
        authToken,
        searchText,
        start,
        end
      );

      if (testimonialData.status === 200) {
        //set user Data
        await setListTestimonialData(testimonialData.data);
        await setListTestimonialDataTotal(testimonialData.total);

        this.setState({
          isLoading: false,
          data: testimonialData.data,
        });
      } else if (testimonialData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listTestimonialData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTestimonialData,
        setListTestimonialDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let testimonialData = await getAllTestimonial(authToken, start, end);

      if (testimonialData.status === 200) {
        //set user Data
        // await setListTestimonialData(testimonialData.data.docs);
        await setListTestimonialData(testimonialData.data);
        await setListTestimonialDataTotal(testimonialData.total);
      } else if (testimonialData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        testimonialSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      testimonialSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      width: 150,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "First Name",
      width: 150,
      key: "first_name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="First Name">
                <div>{item.first_name ? item.first_name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Last Name",
      width: 150,
      key: "last_name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Last Name">
                <div>{item.last_name ? item.last_name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Testimonial Title",
      width: 150,
      key: "title",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Testimonial Title">
                <div>{item.title ? item.title : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Active",
      width: 100,
      key: "is_active",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Testimonial Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {checkPermission(
              this.props.loginUserData.permissions,
              "testimonial",
              "edit"
            ) && (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            )}
            {checkPermission(
              this.props.loginUserData.permissions,
              "testimonial",
              "delete"
            ) && (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteTestimonialData.bind(this, item)}
                    title="Are you sure you want to delete Testimonial?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  async deleteTestimonialData(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteTestimonial(authToken, item._id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Testimonial",
          description: "Successfully deleted",
        });

        /*if (searchText != null && searchText !== "") {
          this.listSearchTestimonialData(startIndex, limitNumber);
          return;
        } */
        this.listTestimonialData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Testimonial",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item._id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Testimonial Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchTestimonialData(startIndex, limitNumber);
          return;
        }
        this.listTestimonialData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (err) {
      notification["error"]({
        message: "Change Testimonial Status",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (testimonialObject, testimonialId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editTestimonial(
        authToken,
        testimonialObject,
        testimonialId
      );
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Testimonial Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchTestimonialData(startIndex, limitNumber);
          return;
        }
        this.listTestimonialData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Testimonial",
        description: e,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedTestimonial(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const {
      listTestimonialData,
      listTestimonialDataTotal,
      searchLoader,
      loginUserData,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listTestimonialData}
          pagination={{
            total: listTestimonialDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.testimonialSelectedData &&
        checkPermission(loginUserData.permissions, "testimonial", "edit") ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            testimonialSelectedData={this.state.testimonialSelectedData}
            onSubmit={this.editSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listTestimonialData: state.testimonial.listTestimonialData,
  listTestimonialDataTotal: state.testimonial.listTestimonialDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListTestimonialData: (listTestimonialData) => {
      dispatch({
        type: "SET_LIST_TESTIMONIAL_DATA",
        listTestimonialData: listTestimonialData,
      });
    },
    setListTestimonialDataTotal: (listTestimonialDataTotal) => {
      dispatch({
        type: "SET_LIST_TESTIMONIAL_DATA_TOTAL",
        listTestimonialDataTotal: listTestimonialDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TestimonialRecordsTable));
