import { callGetApiToServer } from "../callApi";

export async function listCourseGroup(authtoken, skipNumber, limitNumber, courseId) {
	try {
		let header = {
			Accept: "application/json",
			"Content-Type": "application/json",
			authorization: "Berear " + authtoken,
		};

		let endUrl = "v1/auth/course-groups/" + skipNumber + "/" + limitNumber;
		if (courseId) {
			endUrl = "v1/course-group/" + courseId + "/" + skipNumber + "/" + limitNumber;
		}

		let responseData = await callGetApiToServer(header, "GET", endUrl);

		return responseData;
	} catch (err) {
		throw err;
	}
}
