import { callGetApiToServer } from "../callApi";

export async function listUserGroup(authtoken, skipNumber, limitNumber) {
	try {
		let header = {
			Accept: "application/json",
			"Content-Type": "application/json",
			authorization: "Berear " + authtoken,
		};

		let endUrl = "v1/auth/all-user-groups/" + skipNumber + "/" + limitNumber;

		let responseData = await callGetApiToServer(header, "GET", endUrl);

		return responseData;
	} catch (err) {
		throw err;
	}
}
