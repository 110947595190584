import React, { Fragment } from "react";
import {
  UserOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Modal, Divider, Spin, Tabs, Layout, Card, notification } from "antd";
import * as moment from "moment";
import Title from "./Title";
import styled from "styled-components";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CoursePreview from "./CoursePreview";
import Module from "../Module";
import CourseSession from "../CourseSession";
import CourseSessionTopic from "../CourseSessionTopic";
import CourseDataSorting from "./CourseDataSorting";
import Faq from "../Faq";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

const { Content } = Layout;
const { TabPane } = Tabs;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class CourseDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activeCourseTab: "1",
      errors: {},
    };
  }

  input;

  componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(
        this.props.loginUserData.permissions,
        "course",
        "preview"
      )
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  handleOnTabClick = (tabKey) => {
    this.setState({ activeCourseTab: tabKey });
  };

  render() {
    const { isLoading, activeCourseTab } = this.state;
    const { singleCourseData } = this.props;

    return (
      <>
        <Fragment>
          <Title
            parent={`Course`}
            title={singleCourseData.name}
            child={singleCourseData.name}
          />

          <div className="container-fluid pb-5">
            {isLoading ? (
              <div style={{ marginLeft: "20px" }}>
                <Spin
                  size="large"
                  style={{ marginLeft: "480px", marginTop: "130px" }}
                />
              </div>
            ) : (
              <div>
                <Content>
                  <div className="card-container">
                    <Card title="Course Details">
                      <Tabs
                        type="card"
                        onTabClick={this.handleOnTabClick}
                        activeKey={activeCourseTab}
                        style={{ border: "1px solid #f0f0f0" }}
                      >
                        <TabPane tab="Course Overview" key="1">
                          <CoursePreview data={singleCourseData} />
                        </TabPane>
                        <TabPane tab="Modules" key="2">
                          <Module course_id={singleCourseData._id} />
                        </TabPane>
                        <TabPane tab="Sessions" key="3">
                          <CourseSession course_id={singleCourseData._id} />
                        </TabPane>
                        <TabPane tab="Topics" key="4">
                          <CourseSessionTopic
                            course_id={singleCourseData._id}
                          />
                        </TabPane>
                        <TabPane tab="FAQ" key="5">
                          <Faq course_id={singleCourseData._id} />
                        </TabPane>
                        <TabPane tab="Manage Course Content" key="6">
                          <CourseDataSorting course_id={singleCourseData._id} />
                        </TabPane>
                      </Tabs>
                    </Card>
                  </div>
                </Content>
              </div>
            )}
          </div>
        </Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  searchText: state.auth.searchText,
  singleCourseData: state.course.singleCourseData,
  activeCourseTab: state.course.activeCourseTab,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setCourseData: (courseData) => {
      dispatch({
        type: "SET_COURSE_DATA",
        courseData: courseData,
      });
    },
    setCourseToken: (authToken) => {
      dispatch({
        type: "SET_COURSE_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDetailPage));
