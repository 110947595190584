import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUser } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import Title from "./Title";
import AdminRecordsTable from "./AdminRecordsTable";
import { AddAdmin } from "./AddAdmin";
import { addAdmin } from "../../Graphs/Admin/addAdmin";
import { searchAdmin } from "../../Graphs/Admin/searchAdmin";
import { getAllAdmin } from "../../Graphs/Admin/listAdmin";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (this.props.loginUserData.role !== "SUPERADMIN") {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText, userData } = this.props;
      console.log(userData);
      let editPrimaryColor = await localStorage.getItem("primary_color");
      await setRouteName("ADMIN");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listAdminData();
    } catch (e) {
      notification["success"]({
        message: "Admin",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listAdminData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListAdminData,
      setListAdminDataTotal,
    } = this.props;

    try {
      let adminData = await getAllAdmin(authToken, 0, 10);

      if (adminData.status === 200) {
        //set user data
        console.log("admin data", adminData);
        // await setListAdminData(adminData.data.docs);
        await setListAdminData(adminData.data);
        await setListAdminDataTotal(adminData.total);
        // console.log(adminData.data.docs);
        console.log(adminData.data);
        this.setState({
          // userList: adminData.data.docs,
          userList: adminData.data,
          userTotal: adminData.total,
        });
      } else if (adminData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListAdminData,
      setListAdminDataTotal,
    } = this.props;

    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      console.log(values);
      let addAdminData = await addAdmin(authToken, values);

      if (addAdminData.status === 200) {
        notification["success"]({
          message: "New Admin Added Successfully",
        });
        await this.listAdminData();
      } else if (addAdminData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["success"]({
        message: "There was a problem in Adding User",
      });
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listAdminData, listAdminDataTotal } = this.props;
    return (
      <Fragment>
        <Title parent="Admin" title="Admin" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddAdmin primaryColor={primaryColor} onSubmit={this.addSubmit} />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              {
                <AdminRecordsTable
                  listAdminData={listAdminData}
                  listAdminDataTotal={listAdminDataTotal}
                  primaryColor={primaryColor}
                />
              }
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listAdminData: state.admin.listAdminData,
  loginUserData: state.auth.loginUserData,
  listAdminDataTotal: state.admin.listAdminDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListAdminData: (listAdminData) => {
      dispatch({
        type: "SET_LIST_ADMIN_DATA",
        listAdminData: listAdminData,
      });
    },
    setListAdminDataTotal: (listAdminDataTotal) => {
      dispatch({
        type: "SET_LIST_ADMIN_DATA_TOTAL",
        listAdminDataTotal: listAdminDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
