import { callApiToServer } from "../callApi";

export async function editGroup(authToken, dataObject) {
	try {
		let header = {
			Accept: "application/json",
			"Content-Type": "application/json",
			authorization: "Berear " + authToken,
		};

		let body = JSON.stringify(dataObject);

		console.log(body);

		let endUrl = "v1/auth/edit-group-admins";

		console.log("endUrl printed here", endUrl);

		let responseData = await callApiToServer(body, header, "PUT", endUrl);

		return responseData;
	} catch (err) {
		throw err;
	}
}
