import { callApiToServer } from '../callApi';

export async function editLanguage(
  authtoken,
  languageObject,
  languageId
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    let body = JSON.stringify({
      Language_id: languageId,
      name: languageObject.name
    });

    let endUrl = "v1/auth/admin/edit-language";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;

  } catch (err) {
    throw err;
  }
}
