import { callApiToServer } from "../callApi";

export async function editAdmin(authtoken, adminObject, adminId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    
    adminObject['admin_id'] = adminId;
    let body = JSON.stringify(adminObject);

    let endUrl = "v1/auth/admin/edit-admin";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
