import { callApiToServer } from '../callApi';

export async function deleteLanguage(
  authtoken,
  id
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    let body = JSON.stringify({
      Language_id: id
    });

    let endUrl = "v1/auth/admin/delete-language";
    let responseData = await callApiToServer(body, header, "DELETE", endUrl);

    return responseData;

  } catch (err) {
    throw err;
  }
}
