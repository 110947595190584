import { SET_LIST_APPCONTENT_DATA, SET_LIST_APPCONTENT_DATA_TOTAL } from "../constant/actionTypes";
import ConfigDB from '../data/customizer/config';

const initial_state = {
    listAppContentData : null,
    listAppContentDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_APPCONTENT_DATA:
            return { ...state, loading: false, listAppContentData: action.listAppContentData };

        case SET_LIST_APPCONTENT_DATA_TOTAL:
            return { ...state, loading: false, listAppContentDataTotal: action.listAppContentDataTotal };
            
        default: return { ...state };
    }
}
