import { Modal, Button, Divider, Layout, Input, Select, Checkbox } from "antd";
import * as React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { AddCourseBenefitsIcon } from "./AddCourseBenefitsIcon";
import { AddCourseMediaFiles } from "./AddCourseMediaFiles";
import { AddCourseBanner } from "./AddCourseBanner";
import { AddCourseIntroMedia } from "./AddCourseIntroMedia";
import { AddCoursePrimaryMedia } from "./AddCoursePrimaryMedia";
import { PictureOutlined, DeleteFilled } from "@ant-design/icons";
import { listAllDropDownLanguage } from "../../Graphs/Language/listAllDropDownLanguage";
import { listAllDropDownCategory } from "../../Graphs/Category/listAllDropDownCategory";
import { listSubCategoryFromCategory } from "../../Graphs/SubCategory/listSubCategoryFromCategory";
import {
  COURSE_BANNER_PREVIEW_PATH,
  COURSE_BENEFIT_PREVIEW_PATH,
  COURSE_PRIMARY_PREVIEW_PATH,
  COURSE_INTRO_PREVIEW_PATH,
} from "../../constant/comman";
import MediaItem from "../Media/MediaItem";
import EditorField from "../common/EditorField";

const { Content } = Layout;
const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class AddCourse extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.state = {
      data: [],
      viewBannerModal: false,
      viewIntroModal: false,
      viewPrimaryModal: false,
      viewMediaModal: false,
      viewBenefitIconModal: false,
      isLoading: false,
      visible: false,
      selectedCategoryType: "",
      selectedDiffLevel: "",
      selectedValidity: "",
      selectedSubCategoryType: [],
      isPaid: false,
      isRestricted: false,
      isCertified: false,
      isFeatured: false,
      categoryList: [],
      skills: [],
      languages: [],
      inputFields: [],
      benefits: [],
      mediaFields: [],
      media: [],
      primary: [],
      banner: [],
      intro: [],
      selectedLanguage: [],
      allSubCategories: [],
      isShouldComponentUpdate: false,
      longDescription: null,
      errors: {},
    };
  }

  input;
  selectedColorType = "";
  selctedColorKey = "";
  src = [];
  mediaSrc = [];
  skills = [];
  languages = [];
  allLanguages = [];
  allCategories = [];
  allSubCategories = [];
  bannerSrc = {};
  viewPrimaryModalRef;
  viewBannerModalRef;
  viewIntroModalRef;
  viewMediaModalRef;
  viewBenefitIconModalRef;

  difficultyLevelTypes = [
    <Option value={"Beginner"} key="Beginner">
      Beginner
    </Option>,
    <Option value={"Intermediate"} key="Intermediate">
      Intermediate
    </Option>,
    <Option value={"Master"} key="Master">
      Master
    </Option>,
  ];

  courseValididyTypes = [
    <Option value={"3"} key="3">
      3 Month
    </Option>,
    <Option value={"6"} key="6">
      6 Month
    </Option>,
    <Option value={"12"} key="12">
      12 Month
    </Option>,
  ];

  async componentDidMount() {
    const { authToken } = this.props;

    let languageData = await listAllDropDownLanguage(authToken);

    if (languageData.data && languageData.data.length >= 1) {
      languageData.data.map((data, index) => {
        this.allLanguages.push(
          <Option value={data._id} key={data._id}>
            {data.name}
          </Option>
        );
      });
    }

    let categoryData = await listAllDropDownCategory(authToken);

    if (categoryData.data && categoryData.data.length >= 1) {
      categoryData.data.map((data, index) => {
        this.allCategories.push(
          <Option value={data._id} key={data._id}>
            {data.name}
          </Option>
        );
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      this.src = [];
      this.mediaSrc = [];
      this.bannerSrc = {};
    }

    return true;
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.author) {
      errors.author = "Author is required";
    }

    if (!values.total_time) {
      errors.total_time = "Total Time is required";
    }

    if (
      this.state.isRestricted &&
      (!values.age_restriction_count || values.age_restriction_count <= 0)
    ) {
      errors.age_restriction_count = "Restricted Age is required";
    }

    if (this.state.isPaid && (!values.price || values.price <= 0)) {
      errors.price = "Price is required";
    }

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    const {
      media,
      primary,
      banner,
      intro,
      longDescription,
      selectedCategoryType,
      selectedSubCategoryType,
      isPaid,
      isRestricted,
      isCertified,
      isFeatured,
      selectedDiffLevel,
      selectedValidity,
      selectedLanguage,
      skills,
      benefits,
      errors,
    } = this.state;

    const dummyErrors = { ...errors };

    dummyErrors.selectedCategoryType = "";
    dummyErrors.selectedSubCategoryType = "";
    dummyErrors.selectedDiffLevel = "";
    dummyErrors.selectedLanguage = "";
    dummyErrors.skills = "";
    dummyErrors.primary = "";
    dummyErrors.banner = "";
    // dummyErrors.intro = "";
    dummyErrors.media = "";
    dummyErrors.benefits = [];

    let isValidation = false;

    if (!selectedCategoryType) {
      isValidation = true;
      dummyErrors.selectedCategoryType = "Please select category";
    }

    if (!selectedSubCategoryType || selectedSubCategoryType.length <= 0) {
      isValidation = true;
      dummyErrors.selectedSubCategoryType = "Please select sub category";
    }

    if (!selectedValidity) {
      isValidation = true;
      dummyErrors.selectedValidity = "Please select validity";
    }

    if (!selectedDiffLevel) {
      isValidation = true;
      dummyErrors.selectedDiffLevel = "Please select difficulty level";
    }

    if (!selectedLanguage || selectedLanguage.length <= 0) {
      isValidation = true;
      dummyErrors.selectedLanguage = "Please select language";
    }

    if (!skills || skills.length <= 0) {
      isValidation = true;
      dummyErrors.skills = "Please enter skills";
    }

    if (!primary || primary.length <= 0) {
      isValidation = true;
      dummyErrors.primary = "Please select primary image";
    }

    if (!banner || banner.length <= 0) {
      isValidation = true;
      dummyErrors.banner = "Please select banner";
    }

    // if (!intro || intro.length <= 0) {
    //   isValidation = true;
    //   dummyErrors.intro = "Please select intro media";
    // }

    // if (!media || media.length <= 0) {
    //   isValidation = true;
    //   dummyErrors.media = "Please select at least one media";
    // }

    if (!benefits || benefits.length <= 0) {
      isValidation = true;
      dummyErrors.benefits = "Please add at least one benefit";
    } else if (benefits.length >= 1) {
      benefits.map((item, index) => {
        dummyErrors.benefits[index] = {
          name: "",
          short_description: "",
          icon_name: "",
        };
        if (!item.name) {
          isValidation = true;
          dummyErrors.benefits[index]["name"] = "Please enter name";
        }
        if (!item.short_description) {
          isValidation = true;
          dummyErrors.benefits[index]["short_description"] =
            "Please enter short description";
        }
        if (!item.icon_name) {
          isValidation = true;
          dummyErrors.benefits[index]["icon_name"] = "Please select icon";
        }
      });
    }

    console.log("dummyErrors dummyErrors", dummyErrors);

    this.setState({
      errors: dummyErrors,
    });

    if (isValidation) {
      return;
    }

    values.long_description = longDescription;
    values.media = [primary, banner];
    values.category_id = selectedCategoryType;
    values.sub_categories = selectedSubCategoryType.map((item) => ({
      sub_category_id: item,
    }));
    values.is_paid = isPaid;
    values.is_age_restricted = isRestricted;
    values.age_restriction_count = isRestricted;
    values.is_certified = isCertified;
    values.is_featured = isFeatured;
    values.difficulty_level = selectedDiffLevel;
    values.validity = selectedValidity;
    values.languages = selectedLanguage.map((item) => ({
      language_id: item,
    }));
    values.skills = this.skills;
    values.benefits = benefits;

    console.log("for sumitssion values", values);

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
      viewBannerModal: false,
      viewIntroModal: false,
      viewPrimaryModal: false,
      viewMediaModal: false,
      viewBenefitIconModal: false,
      isLoading: false,
      selectedCategoryType: "",
      selectedDiffLevel: "",
      selectedValidity: "",
      selectedSubCategoryType: [],
      isPaid: false,
      isRestricted: false,
      isCertified: false,
      isFeatured: false,
      categoryList: [],
      skills: [],
      languages: [],
      inputFields: [],
      benefits: [],
      mediaFields: [],
      primary: [],
      media: [],
      banner: [],
      intro: [],
      selectedLanguage: [],
      allSubCategories: [],
      longDescription: null,
      errors: {},
    });
  };

  changeCategoryType = (value) => {
    this.setState({
      selectedCategoryType: value,
    });
    //get sub category from the category ID start
    this.getSubCategoriesFromCategory(value);
    //get sub category from the category ID end
  };

  changeSubCategoryType = (value) => {
    this.setState({
      selectedSubCategoryType: value,
    });
  };

  getSubCategoriesFromCategory = async (categoryId) => {
    const { authToken } = this.props;

    this.allSubCategories = [];

    if (categoryId) {
      let subCategoriesData = await listSubCategoryFromCategory(
        authToken,
        categoryId,
        0,
        1000000
      );

      if (subCategoriesData.data && subCategoriesData.data.length >= 1) {
        subCategoriesData.data.map((data, index) => {
          this.allSubCategories.push(
            <Option value={data._id} key={data._id}>
              {data.name}
            </Option>
          );
        });
      }
    }

    this.setState({
      allSubCategories: this.allSubCategories,
    });
  };

  changeDiffiLevel = (value) => {
    this.setState({
      selectedDiffLevel: value,
    });
  };

  changeValidity = (value) => {
    this.setState({
      selectedValidity: value,
    });
  };

  changeLanguage = (value) => {
    this.setState({
      selectedLanguage: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedCategoryType: "",
      media: [],
      primary: [],
      banner: [],
      intro: [],
      errors: {},
      isShouldComponentUpdate: false,
    });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  addBenefits = () => {
    const { benefits } = this.state;
    let dummyQuesions = benefits;
    dummyQuesions.push({
      name: "",
      short_description: "",
      icon_name: "",
      view_benefit_icon_modal: false,
    });

    this.setState({
      benefits: dummyQuesions,
    });
  };

  removeBenefitItem = (index) => {
    const { benefits } = this.state;
    let dummyQuesions = benefits;
    dummyQuesions.splice(index, 1);

    this.setState({
      benefits: dummyQuesions,
    });
  };

  uploadBenefitIconMedia = (name, index) => {
    const { benefits } = this.state;
    let dummyQuesions = benefits;
    dummyQuesions[index]["icon_name"] = name;
    dummyQuesions[index]["view_benefit_icon_modal"] = false;
    this.setState({
      benefits: dummyQuesions,
    });
  };

  onQuestionValueChange = (e, index) => {
    const { benefits } = this.state;
    let dummyQuesions = benefits;
    dummyQuesions[index][e.target.name] = e.target && e.target.value;
    this.setState({
      benefits: dummyQuesions,
    });
  };

  viewQuestionImageModalRef;

  showQuestionImageUpload(index) {
    const { benefits } = this.state;
    let dummyQuesions = benefits;
    dummyQuesions[index]["view_benefit_icon_modal"] = true;
    this.setState(
      {
        benefits: dummyQuesions,
      },
      () => {
        this.viewQuestionImageModalRef.show();
      }
    );
  }

  handleChangeSkills = (skills) => {
    // console.log("===============", event)

    let stateSkills = this.state.skills;

    skills.map((skill) => {
      let tempSkill = skill.trim();
      tempSkill = tempSkill.toLowerCase();
      if (
        !this.state.skills.map((item) => item.toLowerCase()).includes(tempSkill)
      ) {
        this.skills.push({ name: skill });
        stateSkills.push(skill);
      }
    });

    this.setState({ skills: stateSkills });
  };

  //for input change in benefits
  handleChangeInput = (index, event) => {
    const values = [...this.state.inputFields];
    values[index][event.target.name] = event.target.value;
    values[index][event.target.short_description] = event.target.value;
    this.setState({ inputFields: values });
  };

  //file icon change

  handleChangeFileInput = (index, event) => {
    console.log("hey there", event.target.files[0]);
    const values = [...this.state.inputFields];

    values[index]["icon_name"] = event.target.files[0];
    this.setState({ inputFields: values });
  };

  uploadBenefitIcon = (name, index) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    console.log("!!!!!!!!!!!!!!beniifit section", this.state.inputFields);
    if (name != null) {
      console.log("index benefit icon", index);
      this.src[index] = name;
      this.setState({
        viewBenefitIconModal: false,
      });
      //set benefit icon name into form field
      const values = [...this.state.inputFields];
      values[index]["icon_name"] = name;
    } else {
      this.setState({
        viewBenefitIconModal: false,
      });
    }
  };

  // uploadMediaFiles = (mediaData) => {
  //   console.log("!!!!!!!!!!!!!!after upload 1111111 ", mediaData);
  //   if (mediaData && mediaData.length > 0) {
  //     this.setState({
  //       media: [...this.state.media, ...mediaData],
  //       viewMediaModal: false
  //     });
  //   } else {
  //     this.setState({
  //       viewMediaModal: false
  //     });
  //   }
  // }

  uploadBanner = (name, bannerData) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      // this.bannerSrc = bannerData;
      this.setState({
        banner: bannerData,
        viewBannerModal: false,
      });
    } else {
      this.setState({
        viewBannerModal: false,
      });
    }
  };

  uploadPrimary = (name, primaryData) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.setState({
        primary: primaryData,
        viewPrimaryModal: false,
      });
    } else {
      this.setState({
        viewPrimaryModal: false,
      });
    }
  };

  // uploadIntro = (name, introData) => {
  //   console.log("!!!!!!!!!!!!!!after upload ", name);
  //   if (name != null) {
  //     this.setState({
  //       intro: introData,
  //       viewIntroModal: false,
  //     });
  //   } else {
  //     this.setState({
  //       viewIntroModal: false,
  //     });
  //   }
  // };

  //for dynamically adding benefit
  addNewBenefit = () => {
    this.setState({
      inputFields: [
        ...this.state.inputFields,
        {
          name: "",
          short_description: "",
          icon_name: null,
        },
      ],
    });
  };

  // addNewMedia = () => {
  //   this.setState({
  //     mediaFields: [...this.state.mediaFields, {
  //       'media_type': "",
  //       'type': "",
  //       'media_name': ""
  //     }]
  //   })
  // }

  showBannerModal(params) {
    this.setState(
      {
        viewBannerModal: true,
      },
      () => {
        this.viewBannerModalRef.show();
      }
    );
  }

  showPrimaryModal(params) {
    this.setState(
      {
        viewPrimaryModal: true,
      },
      () => {
        this.viewPrimaryModalRef.show();
      }
    );
  }

  showIntroModal(params) {
    this.setState(
      {
        viewIntroModal: true,
      },
      () => {
        this.viewIntroModalRef.show();
      }
    );
  }

  showMediaModal(params) {
    this.setState(
      {
        viewMediaModal: true,
      },
      () => {
        this.viewMediaModalRef.show();
      }
    );
  }

  showBenefitIconModal(params) {
    this.setState(
      {
        viewBenefitIconModal: true,
      },
      () => {
        this.viewBenefitIconModalRef.show();
      }
    );
  }

  onChangeIsPaid() {
    this.setState({
      isPaid: !this.state.isPaid,
    });
  }

  onChangeIsRestricted() {
    this.setState({
      isRestricted: !this.state.isRestricted,
    });
  }

  onChangeIsCertified() {
    this.setState({
      isCertified: !this.state.isCertified,
    });
  }

  onChangeIsFeatured() {
    this.setState({
      isFeatured: !this.state.isFeatured,
    });
  }

  onLongDescEditorChange = (value) => {
    this.setState({ longDescription: value });
  };

  render() {
    const { primaryColor } = this.props;
    const {
      isPaid,
      isRestricted,
      isCertified,
      isFeatured,
      benefits,
      media,
      primary,
      banner,
      intro,
      skills,
      longDescription,
    } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          className="big-modal"
          destroyOnClose={true}
          title="Add Course"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                author: "",
                short_description: "",
                long_description: "",
                summary_description: "",
                price: "",
                total_time: "",
                category_id: "",
                is_paid: false,
                is_age_restricted: false,
                age_restriction_count: "",
                skills: this.state.skills,
                languages: this.state.languages,
                benefits: this.state.inputFields,
              }}
              // enableReinitialize
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Name
                        <Input
                          id="name"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Author
                        <Input
                          id="author"
                          placeholder="Author"
                          value={values.author}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.author && touched.author ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.author}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Short Description
                        <TextArea
                          row={4}
                          id="short_description"
                          placeholder="Short Description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.short_description &&
                        touched.short_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.short_description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        About Course
                        <EditorField
                          id="about-course-editor"
                          value={longDescription}
                          onChange={this.onLongDescEditorChange}
                          onBlur={handleBlur}
                        />
                        {errors.long_description && touched.long_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.long_description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Summary
                        <TextArea
                          row={4}
                          id="summary_description"
                          placeholder="Summery"
                          value={values.summary_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.summary_description &&
                        touched.summary_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.summary_description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Category
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeCategoryType(value)}
                          placeholder="Select Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {this.state.errors.selectedCategoryType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCategoryType}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Sub Category
                        <Select
                          allowClear
                          mode="multiple"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            this.changeSubCategoryType(value)
                          }
                          placeholder="Select Sub Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.allSubCategories}
                        </Select>
                        {this.state.errors.selectedSubCategoryType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedSubCategoryType}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Validity
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeValidity(value)}
                          placeholder="Select Validity"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.courseValididyTypes}
                        </Select>
                        {this.state.errors.selectedValidity ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedValidity}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Difficulty Level
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeDiffiLevel(value)}
                          placeholder="Select Difficulty Level Type"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.difficultyLevelTypes}
                        </Select>
                        {this.state.errors.selectedDiffLevel ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedDiffLevel}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Skills
                        <TagsInput
                          value={skills}
                          onChange={this.handleChangeSkills}
                          onlyUnique={true}
                          placeholder="Skills"
                        />
                        {this.state.errors.skills ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.skills}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Language
                        <Select
                          allowClear
                          mode="multiple"
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeLanguage(value)}
                          placeholder="Select Language"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allLanguages}
                        </Select>
                        {this.state.errors.selectedLanguage ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedLanguage}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Total Time
                        <Input
                          id="total_time"
                          placeholder="Total Time"
                          value={values.total_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.total_time && touched.total_time ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.total_time}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        <Checkbox
                          onChange={(value) => this.onChangeIsFeatured()}
                          checked={isFeatured}
                        >
                          is Featured?
                        </Checkbox>
                        {this.state.errors.isFeatured ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.isFeatured}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <Checkbox
                          onChange={(value) => this.onChangeIsPaid()}
                          checked={isPaid}
                        >
                          is Paid?
                        </Checkbox>
                        {this.state.errors.isPaid ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.isPaid}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Price
                        <Input
                          id="price"
                          placeholder="Price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!isPaid}
                        />
                        {errors.price && touched.price ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.price}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <Checkbox
                          onChange={(value) => this.onChangeIsRestricted()}
                          checked={isRestricted}
                        >
                          Is Age Restricted?
                        </Checkbox>
                        {this.state.errors.isRestricted ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.isRestricted}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Restricted Age
                        <Input
                          id="age_restriction_count"
                          placeholder="Restricted Age"
                          value={values.age_restriction_count}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!isRestricted}
                        />
                        {errors.age_restriction_count &&
                        touched.age_restriction_count ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.age_restriction_count}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <Checkbox
                          onChange={(value) => this.onChangeIsCertified()}
                          checked={isCertified}
                        >
                          is Certified?
                        </Checkbox>
                        {this.state.errors.isCertified ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.isCertified}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Divider></Divider>

                    {/* Course Primary Media Section Start */}

                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showPrimaryModal.bind(this)}
                      >
                        Choose Course Primary Media
                      </Button>

                      {this.state.errors.primary ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.primary}
                        </p>
                      ) : null}

                      {this.state.viewPrimaryModal ? (
                        <AddCoursePrimaryMedia
                          refx={(e) => (this.viewPrimaryModalRef = e)}
                          onUpload={this.uploadPrimary}
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {primary && primary.media_name ? (
                        <MediaItem
                          name={primary.media_name}
                          url={`${COURSE_PRIMARY_PREVIEW_PATH}${primary.media_name}`}
                        />
                      ) : null}
                    </div>

                    {/* Course Primary Media Section End */}

                    <Divider></Divider>

                    {/* Course Banner Image Section Start */}

                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showBannerModal.bind(this)}
                      >
                        Choose Course Banner
                      </Button>

                      <strong>
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {"Recommended Image Ratio: 1920 X 600"}
                        </p>
                      </strong>

                      {this.state.errors.banner ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "90px",
                            marginTop: "5px",
                          }}
                        >
                          {this.state.errors.banner}
                        </p>
                      ) : null}

                      {this.state.viewBannerModal ? (
                        <AddCourseBanner
                          refx={(e) => (this.viewBannerModalRef = e)}
                          onUpload={this.uploadBanner}
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {banner && banner.media_name ? (
                        <MediaItem
                          name={banner.media_name}
                          url={`${COURSE_BANNER_PREVIEW_PATH}${banner.media_name}`}
                        />
                      ) : null}
                    </div>

                    {/* Course Banner Image Section End */}

                    <Divider></Divider>

                    {/* Course Intro Media Section Start */}

                    {/* <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showIntroModal.bind(this)}
                      >
                        Choose Course Intro Media
                      </Button>

                      {this.state.errors.intro ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.intro}
                        </p>
                      ) : null}

                      {this.state.viewIntroModal ? (
                        <AddCourseIntroMedia
                          refx={(e) => (this.viewIntroModalRef = e)}
                          onUpload={this.uploadIntro}
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {intro && intro.thumb_media_name ? (
                        <MediaItem
                          name={intro.media_name}
                          url={`${COURSE_INTRO_PREVIEW_PATH}${intro.media_name}`}
                          thumbMediaUrl={`${COURSE_INTRO_PREVIEW_PATH}${intro.thumb_media_name}`}
                        />
                      ) : null}
                    </div> */}

                    {/* Course Intro Media Section End */}

                    {/* <Divider></Divider> */}

                    {/* Course Media Section Start */}

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field">
                        {/* <label><strong><Divider orientation="left">Media</Divider></strong></label> */}
                    {/* <div className="formik-field-wrap">

                          <div style={{ display: "inline-block", width: "100%", marginTop: "20px", alignItems: "center" }}>
                            <div style={{ float: "left", width: "10%" }}>
                              <PictureOutlined style={{ width: "100%", fontSize: "30px" }} />
                            </div>
                            <Button style={{ width: "90%" }} onClick={this.showMediaModal.bind(this)} >
                              Choose Media Files
                            </Button>

                            {this.state.errors.media ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.media}
                              </p>
                            ) : null}

                            {media && media.length > 0 ? (
                              media.map(media =>
                                <p style={{ fontSize: "small", margin: "0", marginLeft: "5px" }} key={media.media_name}>
                                  {media.media_name}
                                </p>
                              )
                            ) : null}
                            {this.state.viewMediaModal ? (
                              <AddCourseMediaFiles refx={(e) => (this.viewMediaModalRef = e)} onUpload={this.uploadMediaFiles} />
                            ) : null}
                          </div>

                          <div width="100%" style={{ marginTop: "10px" }}>
                            {this.state.errors.uploadMedia != "" ? (
                              <p style={{ color: "red", fontSize: "small", margin: "0" }}>
                                {this.state.errors.uploadMedia}
                              </p>
                            ) : null}
                          </div>

                          <div style={{ marhinTop: "20px", width: "100px", backgroundColor: this.selectedColorType !== "" ? this.selectedColorType : "#ffffff", borderRadius: "10px" }}>
                            <div className="row">
                              {media && media.length > 0 ? (
                                media.map((media) =>
                                  <div className="col-2">
                                    <img src={`${WEBURL}course/${media.media_name}`} alt="Media" style={{ width: "100px" }} key={media.media_name} />
                                  </div>
                                )
                              ) : null}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div> */}

                    {/* Course Media Section End */}

                    {/* <Divider></Divider> */}

                    {/* Course Benefit Section Start */}
                    <>
                      <div style={{ marginTop: "15px" }}>
                        <Button
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={() => this.addBenefits()}
                        >
                          Add Benefit
                        </Button>
                      </div>

                      <div style={{ marginTop: "15px" }}>
                        {benefits.map((data, index) => {
                          return (
                            <div
                              style={{
                                marginBottom: "15px",
                                display: "inline-block",
                                width: "100%",
                                paddingBottom: "15px",
                                borderBottom: "4px dashed gray",
                              }}
                              key={index}
                            >
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <span
                                  style={{ fontWeight: "bold", float: "left" }}
                                >
                                  {`Benefit ${index + 1}`}
                                </span>
                                <span
                                  style={{ fontWeight: "bold", float: "right" }}
                                >
                                  <Button
                                    shape="circle"
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                      this.removeBenefitItem(index);
                                    }}
                                  />
                                </span>
                              </div>

                              <div className="formik-field_wrap custom30Width">
                                <div className="formik-field_wrap">
                                  <div className="formik-field">
                                    <span style={{ fontWeight: "bold" }}>
                                      Name
                                    </span>
                                    <Input
                                      id="text"
                                      placeholder="Text"
                                      name="name"
                                      value={benefits[index]["name"]}
                                      onChange={(e) =>
                                        this.onQuestionValueChange(e, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                    {this.state.errors.benefits &&
                                    this.state.errors.benefits[index] &&
                                    this.state.errors.benefits[index][
                                      "name"
                                    ] ? (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "small",
                                          margin: "0",
                                        }}
                                      >
                                        {
                                          this.state.errors.benefits[index][
                                            "name"
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="formik-field_wrap">
                                  <div className="formik-field">
                                    <span style={{ fontWeight: "bold" }}>
                                      Short Description
                                    </span>
                                    <TextArea
                                      row={4}
                                      id="short_description"
                                      placeholder="Short Description"
                                      name="short_description"
                                      value={
                                        benefits[index]["short_description"]
                                      }
                                      onChange={(e) =>
                                        this.onQuestionValueChange(e, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                    {this.state.errors.benefits &&
                                    this.state.errors.benefits[index] &&
                                    this.state.errors.benefits[index][
                                      "short_description"
                                    ] ? (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "small",
                                          margin: "0",
                                        }}
                                      >
                                        {
                                          this.state.errors.benefits[index][
                                            "short_description"
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              {/* Course Benefit Media Section Start */}

                              <div
                                className="formik-field_wrap"
                                style={{ marginTop: "5px" }}
                              >
                                <div className="formik-field">
                                  <Button
                                    style={{ width: "100%" }}
                                    onClick={this.showQuestionImageUpload.bind(
                                      this,
                                      index
                                    )} //this.showViewModal.bind(this)
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Choose Benefit Icon
                                    </span>
                                  </Button>

                                  {this.state.errors.benefits &&
                                  this.state.errors.benefits[index] &&
                                  this.state.errors.benefits[index][
                                    "icon_name"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.benefits[index][
                                          "icon_name"
                                        ]
                                      }
                                    </p>
                                  ) : null}

                                  <br />

                                  {benefits[index][
                                    "view_benefit_icon_modal"
                                  ] ? (
                                    <AddCourseBenefitsIcon
                                      refx={(e) =>
                                        (this.viewQuestionImageModalRef = e)
                                      }
                                      onUpload={(name) =>
                                        this.uploadBenefitIconMedia(name, index)
                                      }
                                      selectedMediaType={"question"}
                                    />
                                  ) : null}

                                  <div
                                    style={{
                                      marginTop: "20px",
                                      width: "100%",
                                      backgroundColor:
                                        this.selectedColorType !== ""
                                          ? this.selectedColorType
                                          : "#ffffff",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    {benefits[index]["icon_name"] ? (
                                      <MediaItem
                                        name={benefits[index]["icon_name"]}
                                        url={`${COURSE_BENEFIT_PREVIEW_PATH}${benefits[index]["icon_name"]}`}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {/* Course Benefit Media Section End */}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.errors.benefits &&
                      typeof this.state.errors.benefits === "string" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.benefits}
                        </p>
                      ) : null}
                    </>

                    {/* Course Benefit Section End */}

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

export default connect()(withRouter(AddCourse));
