import * as React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/Editor.css";

class EditorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };

    this.focus = () => this.refs.editor.focus();

    this.onChange = (editorState) => {
      const content = editorState.getCurrentContent();
      if (editorState.getCurrentContent().hasText()) {
        this.props.onChange(draftToHtml(convertToRaw(content)));
      } else {
        this.props.onChange(null);
      }
      this.setState({ editorState });
    };
  }

  /**
   * componentDidMount check the input value attribute has any value or not
   */
  componentDidMount() {
    console.log("editor did mount")
    if (this.props.value) {
      const contentBlock = htmlToDraft(this.props.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        this.setState({
          editorState: EditorState.createWithContent(contentState),
        });
      }
    }
  }

  toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      // "colorPicker",
      "link",
      // "embedded",
      // "emoji",
      // "remove",
      "history",
    ],
  };

  render() {
    const { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="rich-text-editor-wrapper"
        editorClassName="rich-text-editor"
        onEditorStateChange={this.onChange}
        toolbar={this.toolbarOptions}
        onBlur={this.props.onBlur}
      />
    );
  }
}

export default EditorField;
