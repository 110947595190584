import { callApiToServer } from "../callApi";

export async function saveCourseSortData(authtoken, sortObject) {
  console.log('calling');
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    console.log('!!!!!!!sortObject', sortObject);
    let body = JSON.stringify(sortObject);

    let endUrl = "v1/auth/sort-course";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
