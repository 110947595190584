import {
	SET_LIST_GROUP_DATA,
	SET_LIST_GROUP_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
	listGroupData: null,
	listGroupDataTotal: 0
};

export default (state = initial_state, action) => {
	switch (action.type) {
		case SET_LIST_GROUP_DATA:
			return {
				...state,
				loading: false,
				listGroupData: action.listGroupData,
			};

		case SET_LIST_GROUP_DATA_TOTAL:
			return {
				...state,
				loading: false,
				listGroupDataTotal: action.listGroupDataTotal,
			};

		default:
			return { ...state };
	}
};
