import { callGetApiToServer } from '../callApi';

export async function listAllDropDownCourseSession(
  authtoken,
  skip,
  limit,
  courseId
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    }

    let endUrl = "v1/sessions"
    if (courseId) {
      endUrl = "v1/auth/admin/course-sessions-active/" + skip + "/" + limit + "/" + courseId;
    }
    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
