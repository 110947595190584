import { Modal, Button, Divider, Layout, Input, Select, Checkbox } from "antd";
import * as React from "react";
import { Formik } from "formik";
import * as la from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { AddCourseSessionMediaFiles } from "./AddCourseSessionMediaFiles";
import { PictureOutlined } from "@ant-design/icons";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { COURSE_SESSION_MEDIA_PREVIEW_PATH } from "../../constant/comman";
import MediaItem from "../Media/MediaItem";

const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      viewBannerModal: false,
      viewMediaModal: false,
      viewBenefitIconModal: false,
      isLoading: false,
      visible: false,
      selectedCourseType: "",
      selectedModuleType: "",
      selectedParentType: "course",
      isPaid: false,
      isRestricted: false,
      categoryList: [],
      headerPoints: [],
      languages: [],
      inputFields: [],
      mediaFields: [],
      media: [],
      isShouldComponentUpdate: false,
      errors: {
        selectedParentType: "",
      },
    };
  }

  input;
  selectedColorType = "";
  selctedColorKey = "";
  headerPoints = [];
  allCourses = [];
  allModules = [];

  allTypes = [
    <Option value={"course"} key="course">
      Course
    </Option>,
    <Option value={"module"} key="module">
      Module
    </Option>,
  ];

  viewMediaModalRef;

  async componentDidMount() {
    const { authToken, data } = this.props;

    let headerPoints = [];

    let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

    if (courseData.data && courseData.data.length >= 1) {
      courseData.data.map((data, index) => {
        this.allCourses.push(
          <Option value={data._id} key={data._id}>
            {data.name}
          </Option>
        );
      });
    }

    // let moduleData = await listAllDropDownModule(authToken);

    // if (moduleData.data && moduleData.data.length >= 1) {
    // 	moduleData.data.map((data, index) => {
    // 		this.allModules.push(
    // 			<Option disabled={!data.is_active} value={data.courseData[0]._id + "-" + data._id}>
    // 				{data.title}
    // 			</Option>
    // 		);
    // 	});
    // }

    // data.header_points.map(item => {
    // 	headerPoints.push(item.header);
    // })

    // this.headerPoints = data.header_points;

		console.log("datadata", data)

    this.setState({
      // selectedCourseType: data.item.courseData && data.item.courseData[0] && data.item.courseData[0]._id ? data.item.courseData[0]._id : "",
      selectedCourseType:
        data.courseData && data.courseData[0] && data.courseData[0]._id
          ? data.courseData[0]._id
          : "",
      selectedModuleType: data.module_id,
      // selectedParentType: data.parent_type,
      // headerPoints: headerPoints,
      media: data.courseSessionMediaData,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
    }

    return true;
  }

  validateForm = (values) => {
    const { selectedCourseType, media } = this.state;

    const errors = {};

    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    // if (!values.sort_number) {
    // 	errors.sort_number = "Sort Number is required"
    // }

    if (!values.time) {
      errors.time = "Time is required";
    }

    if (!values.short_description) {
      errors.short_description = "Short Description is required";
    }

    if (!selectedCourseType) {
      errors.selectedCourseType = "Please select course";
    }

    // if (!media || media.length <= 0) {
    // 	errors.media = "Please select at least one media";
    // }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  handleSubmit = async (values, action) => {
    const {
      selectedParentType,
      selectedCourseType,
      selectedModuleType,
      media,
      errors,
    } = this.state;

    values.session_id = this.props.data._id;
    values.course_id = selectedCourseType;
    // values.media = [...media];
    // values.header_points = this.headerPoints;

    console.log("for sumitssion values", values);

    // if (selectedParentType == "module") {
    // 	let tempValue = [];
    // 	if (selectedModuleType) {
    // 		tempValue = selectedModuleType.split("-");
    // 		values.course_id = tempValue[0];
    // 		values.module_id = tempValue[1];
    // 	}
    // }

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      selectedCourseType: "",
      selectedModuleType: "",
      isShouldComponentUpdate: false,
    });
  };

  changeCourseType = (value) => {
    this.setState({
      selectedCourseType: value,
      selectedModuleType: "",
    });
  };

  changeModuleType = (value) => {
    this.setState({
      selectedModuleType: value,
    });
  };

  changeParentType = (value) => {
    this.setState({
      selectedModuleType: "",
      selectedCourseType: "",
      selectedParentType: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      // media: [],
      errors: {},
      selectedCourseType: "",
      isShouldComponentUpdate: false,
    });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  // handleChangeHeaderPoints = (headerPoints) => {
  // 	let stateHeaderPoints = this.state.headerPoints;

  // 	headerPoints.map(headerPoint => {
  // 		let tempHp = headerPoint.trim();
  // 		tempHp = tempHp.toLowerCase();
  // 		if (!this.state.headerPoints.map(item => item.toLowerCase()).includes(tempHp)) {
  // 			this.headerPoints.push({ "header": headerPoint })
  // 			stateHeaderPoints.push(headerPoint);
  // 		}
  // 	});

  // 	console.log(this.headerPoints)

  // 	this.setState({ headerPoints: stateHeaderPoints });
  // }

  // uploadMediaFiles = (mediaData) => {
  // 	console.log("!!!!!!!!!!!!!!after upload 1111111 ", mediaData);
  // 	if (mediaData && mediaData.length > 0) {
  // 		this.setState({
  // 			media: [...this.state.media, ...mediaData],
  // 			viewMediaModal: false
  // 		});
  // 	} else {
  // 		this.setState({
  // 			viewMediaModal: false
  // 		});
  // 	}
  // }

  // showMediaModal(params) {
  // 	this.setState(
  // 		{
  // 			viewMediaModal: true
  // 		},
  // 		() => {
  // 			this.viewMediaModalRef.show();
  // 		}
  // 	);
  // }

  render() {
    const { primaryColor, course_id, data } = this.props;
    const {
      selectedParentType,
      selectedModuleType,
      selectedCourseType,
      media,
    } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            name: data.name,
            course_id: data.course_id,
            // sort_number: data.sort_number,
            time: data.time,
            short_description: data.short_description,
            long_description: data.long_description,
          }}
          enableReinitialize
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Name
                    <Input
                      id="name"
                      placeholder="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : null}
                  </div>

                  {/* <div className="formik-field-right">
										Sort Number
										<Input
											id="sort_number"
											placeholder="Sort Number"
											value={values.sort_number}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.sort_number && touched.sort_number ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{errors.sort_number}
											</p>
										) : null}
									</div> */}
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Course
                    {course_id ? (
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Course"
                        disabled
                        value={selectedCourseType}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.allCourses}
                      </Select>
                    ) : (
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(value) => this.changeCourseType(value)}
                        placeholder="Select Course"
                        showSearch
												value={selectedCourseType}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.allCourses}
                      </Select>
                    )}
                    {errors.selectedCourseType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.selectedCourseType}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Time
                    <Input
                      id="time"
                      placeholder="Time"
                      value={values.time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.time && touched.time ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.time}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* <div className="formik-field_wrap">
									<div className="formik-field-left">
										Type
										<Select
											style={{ width: "100%" }}
											onChange={(value) => this.changeParentType(value)}
											placeholder="Select Type"
											showSearch
											value={selectedParentType}
											// defaultValue="subAdmin"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
										>
											{this.allTypes}
										</Select>
										{this.state.errors.selectedParentType ? (
											<p
												style={{
													color: "red",
													fontSize: "small",
													margin: "0",
												}}
											>
												{this.state.errors.selectedParentType}
											</p>
										) : null}
									</div>

									{selectedParentType == "course" ?
										<div className="formik-field-right">
											Course
											<Select
												allowClear
												style={{ width: "100%" }}
												onChange={(value) => this.changeCourseType(value)}
												placeholder="Select Course"
												showSearch
												disabled={course_id ? true : false}
												value={selectedCourseType}
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.allCourses}
											</Select>
											{this.state.errors.selectedCourseType ? (
												<p
													style={{
														color: "red",
														fontSize: "small",
														margin: "0",
													}}
												>
													{this.state.errors.selectedCourseType}
												</p>
											) : null}
										</div>
										:
										<div className="formik-field-right">
											Module
											<Select
												allowClear
												style={{ width: "100%" }}
												onChange={(value) => this.changeModuleType(value)}
												placeholder="Select Module"
												showSearch
												value={selectedModuleType}
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.allModules}
											</Select>
											{this.state.errors.selectedModuleType ? (
												<p
													style={{
														color: "red",
														fontSize: "small",
														margin: "0",
													}}
												>
													{this.state.errors.selectedModuleType}
												</p>
											) : null}
										</div>
									}
								</div> */}

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Time
                    <Input
                      id="time"
                      placeholder="Time"
                      value={values.time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.time && touched.time ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.time}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field">
                    Short Description
                    <TextArea
                      row={4}
                      id="short_description"
                      placeholder="Short Description"
                      value={values.short_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.short_description && touched.short_description ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.short_description}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field">
                    Long Description
                    <TextArea
                      row={4}
                      id="long_description"
                      placeholder="Long Description"
                      value={values.long_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                {/* <div className="formik-field_wrap">
									<div className="formik-field">
										Header Points
										<TagsInput
											value={this.state.headerPoints}
											onChange={this.handleChangeHeaderPoints}
											onlyUnique={true}
											placeholder="Header Points"
										/>
									</div>
								</div> */}

                {/* <Divider></Divider> */}

                {/* Course Session Media Section Start */}
                {/* <div className="formik-field_wrap">
									<div className="formik-field">
										<div className="formik-field-wrap">
											<div style={{ display: "inline-block", width: "100%", marginTop: "20px", alignItems: "center" }}>
												<div style={{ float: "left", width: "10%" }}>
													<PictureOutlined style={{ width: "100%", fontSize: "30px" }} />
												</div>
												<Button style={{ width: "90%" }} onClick={this.showMediaModal.bind(this)} >
													Choose Media Files
												</Button>

												{errors.media ? (
													<p style={{ color: "red", fontSize: "small", margin: "0", }} >
														{errors.media}
													</p>
												) : null}

												{this.state.viewMediaModal ? (
													<AddCourseSessionMediaFiles refx={(e) => (this.viewMediaModalRef = e)} onUpload={this.uploadMediaFiles} />
												) : null}
											</div>

											<div style={{ marginTop: "20px", width: "100%", backgroundColor: this.selectedColorType !== "" ? this.selectedColorType : "#ffffff", borderRadius: "10px" }}>
												{media && media.length > 0 ? (
													media.map((media) =>
														<MediaItem name={media.media_name} url={`${COURSE_SESSION_MEDIA_PREVIEW_PATH}${media.media_name}`} thumbMediaUrl={`${COURSE_SESSION_MEDIA_PREVIEW_PATH}${media.thumb_media_name}`} />
													)
												) : null}
											</div>

										</div>
									</div>
								</div> */}
                {/* Course Session Media Section End */}

                {/* <Divider></Divider> */}

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default connect()(withRouter(EditForm));
