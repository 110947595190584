import { Modal, Button } from "antd";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";
import EditorField from "../common/EditorField";

const { Content } = Layout;

var amenities = [];

export class AddAppContentModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      isLoading: false,
      isShouldComponentUpdate: false,
      description: null,
    };
  }

  input;

  onDescriptionChange = (description) => {
    this.setState({ description });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};

    const { description } = this.state;

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.slug_name) {
      errors.slug_name = "Slug is required";
    }

    if (!description || description == "" || description == null) {
      errors.description = "Description is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { description } = this.state;

    values.description = description;

    console.log("Basic Value - ", values);

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      description: null,
    });
  };

  showModal = () => {
    console.log("!!!!!!visible");
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
    });
  };

  render() {
    const { primaryColor } = this.props;
    const { description } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          Add New
        </div>
        <Modal
          destroyOnClose={true}
          title="Add New"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                slug_name: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Name
                        <Input
                          id="name"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Slug
                        <Input
                          id="slug_name"
                          placeholder="Slug"
                          value={values.slug_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.slug_name && touched.slug_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.slug_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div style={{ marginBottom: "15px" }}>
                      <div className="input-label"> Description </div>
                      <EditorField
                        id="desc-editor"
                        value={description}
                        onChange={this.onDescriptionChange}
                        onBlur={handleBlur}
                      />
                      <div className="error-message">
                        {errors.description && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.description}
                          </p>
                        )}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
