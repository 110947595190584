import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, Row, Checkbox } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { MODULES, ROLES } from "../../constant/comman";

export class UpdateRoleForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      errors: {},
    };
  }

  input;

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    console.log(this.props._id);

    this.props.onSubmit(values, this.props.data._id);

    this.setState({
      visible: false,
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  render() {
    const { primaryColor, data } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            email: data.email,
            phone: data.phone,
            first_name: data.first_name,
            last_name: data.last_name,
            permissions: data.permissions,
            role: data.role,
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  {MODULES.map((moduleName, moduleIndex) => (
                    <div key={moduleIndex}>
                      <div className="col-md-12">
                        <div className="row">
                          <label>
                            <strong>{moduleName.label}</strong>
                          </label>
                        </div>
                        <div className="row">
                          <div className="formik-field">
                            {ROLES[moduleName.value].map(
                              (roleName, roleIndex) => (
                                <span key={roleIndex}>
                                  {values.role === "superAdmin" ? (
                                    <Checkbox
                                      checked
                                      className="disabled"
                                      name={`permissions.${
                                        moduleName.value
                                      }.${roleName.toLowerCase()}`}
                                    >
                                      {roleName}
                                    </Checkbox>
                                  ) : (
                                    <Checkbox
                                      onChange={handleChange}
                                      checked={
                                        values.permissions[moduleName.value] &&
                                        values.permissions[moduleName.value][
                                          roleName.toLowerCase()
                                        ]
                                          ? values.permissions[
                                              moduleName.value
                                            ][roleName.toLowerCase()]
                                          : false
                                      }
                                      name={`permissions.${
                                        moduleName.value
                                      }.${roleName.toLowerCase()}`}
                                    >
                                      {roleName}
                                    </Checkbox>
                                  )}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <Divider></Divider>
                    </div>
                  ))}

                  {values.role !== "superAdmin" && (
                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(UpdateRoleForm));
