import { callGetApiToServer } from "../callApi";

export async function getAllModule(authtoken, skip, limit, courseId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = "v1/auth/admin/topics/module/" + skip + "/" + limit;
    if (courseId) {
      endUrl = "v1/admin/session-topic/" + skip + "/" + limit + "/" + courseId + "/module";
    }

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
