import {
  Modal,
  Button,
  Divider,
  Layout,
  Input,
  Select,
  Checkbox,
  Radio,
  InputNumber,
} from "antd";
import * as React from "react";
import { Formik } from "formik";
import * as la from "lodash";
import { addCourseSession } from "../../Graphs/CourseSession/addCourseSession";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { listAllDropDownCourseSession } from "../../Graphs/CourseSession/listAllDropDownCourseSession";
import { QuestionComponent } from "./QuestionComponent";
import EditorField from "../common/EditorField";

const { Content } = Layout;
const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class AddCourseSessionTopic extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      viewMediaModal: false,
      isLoading: false,
      visible: false,
      isShouldComponentUpdate: false,
      isSample: false,
      inputFields: [],
      allQuestions: [],
      selectedAnswerType: [],
      selectedQuestionType: [],
      media: [],
      allCoursesOptions: [],
      allSessionsOptions: [],
      selectedType: "",
      selectedParentType: "course",
      selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      longDescription: null,
      errors: {},
    };
  }

  input;
  selectedColorType = "";
  selctedColorKey = "";
  src = [];
  mediaSrc = [];
  allCourses = [];
  allModules = [];
  allSessions = [];
  viewMediaModalRef;

  allTypes = [
    <Option value={"study"} key="study">
      Study
    </Option>,
    <Option value={"quiz"} key="quiz">
      Quiz
    </Option>,
  ];

  allParentTypes = [
    <Option value={"course"} key="course">
      Course
    </Option>,
    // <Option value={"module"} key="module">Module</Option>,
    <Option value={"session"} key="session">
      Session
    </Option>,
  ];

  // allQuesTypes = [
  //   <Option value={"Text"} key="Text">
  //     Text
  //   </Option>,
  //   <Option value={"Audio"} key="Audio">
  //     Audio
  //   </Option>,
  //   <Option value={"Video"} key="Video">
  //     Video
  //   </Option>,
  //   // <Option value={"Code Snipet"} key="Code Snipet">Code Snipet</Option>,
  // ];

  // allAnsTypes = [
  //   <Option value={"multiChoies"} key="multiChoies">
  //     Multiple Choies
  //   </Option>,
  //   <Option value={"singleChoies"} key="singleChoies">
  //     Single Choies
  //   </Option>,
  //   <Option value={"descriptive"} key="descriptive">
  //     Descriptive
  //   </Option>,
  //   // <Option value={"practical"} key="practical">Pratical</Option>,
  // ];

  async componentDidMount() {
    const { authToken, course_id } = this.props;

    let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

    if (courseData.data && courseData.data.length >= 1) {
      courseData.data.map((data, index) => {
        this.allCourses.push(
          <Option value={data._id} key={index}>
            {data.name}
          </Option>
        );
      });
    }

    this.setState({
      allCoursesOptions: this.allCourses,
    });

    // let moduleData = await listAllDropDownModule(authToken);

    // if (moduleData.data && moduleData.data.length >= 1) {
    //   moduleData.data.map((data, index) => {
    //     this.allModules.push(
    //       <Option value={data.courseData[0]._id + "-" + data._id}>
    //         {data.title}
    //       </Option>
    //     );
    //   });
    // }

    if (course_id) {
      this.setState({
        selectedCourseType: course_id,
      });
    }

    //get course session data if course id available get data based on that else all data retrieve start
    this.getSessionData(course_id);
    //get course session data if course id available get data based on that else all data retrieve end
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      this.mediaSrc = [];
    }

    return true;
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    // if (!values.sort_number) {
    //   errors.sort_number = "Sort Number is required"
    // }

    // if (!values.time) {
    //   errors.time = "Time is required";
    // }

    // if (!values.short_description) {
    //   errors.short_description = "Short Description is required";
    // }

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    const {
      errors,
      allQuestions,
      selectedSessionType,
      selectedCourseType,
      selectedType,
      selectedParentType,
      selectedModuleType,
      longDescription,
      isSample,
      // media,
    } = this.state;

    let dummyErrors = { ...errors };

    let errorFlag = false;

    let isValidation = false;

    dummyErrors.selectedParentType = "";
    dummyErrors.selectedType = "";
    dummyErrors.selectedCourseType = "";
    dummyErrors.selectedModuleType = "";
    dummyErrors.selectedSessionType = "";
    dummyErrors.media = "";
    dummyErrors.allQuestions = [];

    if (!selectedParentType) {
      isValidation = true;
      dummyErrors.selectedParentType = "Please select parent type";
    } else {
      if (selectedParentType === "course" && !selectedCourseType) {
        isValidation = true;
        dummyErrors.selectedCourseType = "Please select course";
      }

      if (selectedParentType === "session" && !selectedSessionType) {
        isValidation = true;
        dummyErrors.selectedSessionType = "Please select session";
      }

      // if (selectedParentType == "module" && !selectedModuleType) {
      //   isValidation = true;
      //   dummyErrors.selectedModuleType = "Please select module";
      // }
    }

    if (!selectedType) {
      isValidation = true;
      dummyErrors.selectedType = "Please select type";
    }

    if (selectedType === "study" && !values.media && !longDescription) {
      isValidation = true;
      dummyErrors.media = "Please enter media URL or description";
    }

    if (selectedType === "quiz") {
      if (!allQuestions || allQuestions.length <= 0) {
        isValidation = true;
        dummyErrors.allQuestions = "Please add at least one question";
      } else if (allQuestions.length >= 1) {
        allQuestions.map((item, index) => {
          dummyErrors.allQuestions[index] = {
            title: "",
            ques_type: "",
            ans_type: "",
            options: "",
            media_name: "",
            // short_description: "",
            // long_description: "",
            // marks: "",
          };

          if (!item.title) {
            isValidation = true;
            dummyErrors.allQuestions[index]["title"] = "Please enter title";
          }

          if (!item.ques_type) {
            isValidation = true;
            dummyErrors.allQuestions[index]["ques_type"] =
              "Please select question type";
          }

          if (!item.ans_type) {
            isValidation = true;
            dummyErrors.allQuestions[index]["ans_type"] =
              "Please select answer type";
          }

          // if (!item.short_description) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["short_description"] =
          //     "Please enter description";
          // }

          // if (!item.long_description) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["long_description"] =
          //     "Please enter long description";
          // }

          // if (!item.marks) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["marks"] = "Please enter marks";
          // }

          if (
            (item.ans_type === "multiChoies" ||
              item.ans_type === "singleChoies") &&
            (!item.options || item.options <= 0)
          ) {
            isValidation = true;
            dummyErrors.allQuestions[index]["options"] =
              "Please provide answer options";
          }

          if (
            item.ques_type === "Audio" &&
            (!item.media_name || item.media_name <= 0)
          ) {
            isValidation = true;
            dummyErrors.allQuestions[index]["media_name"] =
              "Please select question media";
          }

          if (item.ques_type === "Video" && !item.media_name) {
            isValidation = true;
            dummyErrors.allQuestions[index]["media_name"] =
              "Please enter question media URL";
          }
        });
      }
    }

    this.setState({
      errors: dummyErrors,
    });

    console.log("validation", isValidation);

    if (isValidation) {
      return;
    }

    if (selectedType === "study" && longDescription) {
      values.long_description = longDescription;
    }
    values.is_module = false;
    values.is_sample = isSample;
    values.course_id = selectedCourseType;
    values.session_id = selectedSessionType;
    values.parent_type = selectedParentType;
    values.type = selectedType;
    values.questions = allQuestions;
    // values.media = [...media];
    // values.module_id = selectedModuleType;

    // if (selectedParentType == "module") {
    //   let tempValue = [];
    //   if (selectedModuleType) {
    //     tempValue = selectedModuleType.split("-");
    //     values.course_id = tempValue[0];
    //     values.module_id = tempValue[1];
    //     values.session_id = null;
    //   }
    // }

    if (selectedParentType === "session") {
      let tempValue = [];
      if (selectedSessionType) {
        tempValue = selectedSessionType.split("-");
        values.course_id = tempValue[0];
        values.session_id = tempValue[1];
        values.module_id = null;
      }
    }

    console.log("for sumitssion values", values);

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
      viewMediaModal: false,
      isLoading: false,
      isSample: false,
      inputFields: [],
      allQuestions: [],
      selectedAnswerType: [],
      selectedQuestionType: [],
      media: [],
      selectedType: "",
      selectedParentType: "course",
      selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      errors: {},
    });
  };

  // resetRadioButtonValue = (quesions, qIndex) => {
  //   if (quesions[qIndex]["options"].length >= 1) {
  //     quesions[qIndex]["options"] = quesions[qIndex]["options"].map((item) => {
  //       item.is_correct = false;
  //       return item;
  //     });
  //   }
  // };

  changeType = (value) => {
    this.setState({
      selectedType: value,
      allQuestions: [],
      media: [],
    });
  };

  changeParentType = (value) => {
    const { course_id } = this.props;
    this.setState({
      selectedCourseType: course_id ? course_id : "",
      selectedModuleType: "",
      selectedSessionType: "",
      selectedParentType: value,
    });
  };

  changeCourseType = (value) => {
    this.setState({
      selectedCourseType: value,
      selectedModuleType: "",
      selecetdSessionType: "",
    });
  };

  changeModuleType = (value) => {
    this.setState({
      selectedModuleType: value,
      selecetdSessionType: "",
    });
  };

  changeSessionType = (value) => {
    this.setState({
      selectedSessionType: value,
      selectedModuleType: "",
    });
  };

  // changeAnswerType = (value, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["ans_type"] = value;

  //   this.resetRadioButtonValue(dummyQuesions, index);

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // changeQuestionType = (value, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["ques_type"] = value;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  getSessionData = async (courseId) => {
    const { authToken } = this.props;

    this.allSubCategories = [];

    let sessionData = await listAllDropDownCourseSession(
      authToken,
      0,
      100000,
      courseId
    );

    if (sessionData.data && sessionData.data.length >= 1) {
      sessionData.data.map((data, index) => {
        if (data.courseData && data.courseData[0]) {
          this.allSessions.push(
            <Option value={data.courseData[0]._id + "-" + data._id} key={index}>
              {data.name}
            </Option>
          );
        }
      });
    }

    this.setState({
      allSessionsOptions: this.allSessions,
    });
  };

  onChangeIsFeatured() {
    this.setState({
      isSample: !this.state.isSample,
    });
  }

  onDescriptionChange = (description) => {
    this.setState({ longDescription: description });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      media: [],
      allQuestions: [],
      selectedType: "",
      selectedParentType: "course",
      // selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      errors: {},
      isShouldComponentUpdate: false,
    });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  uploadMediaFiles = (mediaData) => {
    console.log("!!!!!!!!!!!!!!after upload 1111111 ", mediaData);
    if (mediaData && mediaData.length > 0) {
      this.mediaSrc = mediaData;
      this.setState({
        media: mediaData,
        viewMediaModal: false,
      });
    } else {
      this.setState({
        viewMediaModal: false,
      });
    }
  };

  showMediaModal(params) {
    this.setState(
      {
        viewMediaModal: true,
      },
      () => {
        this.viewMediaModalRef.show();
      }
    );
  }

  // addQuestions = () => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions.push({
  //     title: "",
  //     ques_type: "",
  //     ans_type: "",
  //     media_name: "",
  //     short_description: "",
  //     // long_description: "",
  //     marks: 0,
  //     options: [],
  //     view_question_media_modal: false,
  //     view_answer_image_modal: false,
  //     is_madatory: true,
  //   });

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // addOptions = (index) => {
  //   console.log("test log ", index);
  //   const { allQuestions } = this.state;

  //   let dummyOptions = allQuestions[index]["options"];
  //   let dummyQuesions = allQuestions;

  //   dummyOptions.push({
  //     title: "",
  //     is_correct: false,
  //     is_madatory: true,
  //   });

  //   dummyQuesions[index]["options"] = dummyOptions;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // removeQuestionItem = (index) => {
  //   console.log("!!!!!removeQuestionItem", index);
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions.splice(index, 1);

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // removeQuestionOptionItem = (index, optIndex) => {
  //   console.log("!!!!!removeQuestionOptionItem", optIndex);
  //   const { allQuestions } = this.state;

  //   let dummyOptions = allQuestions[index]["options"];
  //   let dummyQuesions = allQuestions;

  //   dummyOptions.splice(optIndex, 1);

  //   dummyQuesions[index]["options"] = dummyOptions;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // uploadQuestionMedia = (name, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["media_name"] = name;
  //   dummyQuesions[index]["view_question_media_modal"] = false;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // uploadAnswerImage = (name, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["answer_audio_url"] = name;
  //   dummyQuesions[index]["view_answer_image_modal"] = false;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // viewQuestionMediaModalRef;

  // showQuestionMediaUpload = (index) => {
  //   const { allQuestions } = this.state;

  //   let dummyQuesions = allQuestions;

  //   dummyQuesions[index]["view_question_media_modal"] = true;

  //   this.setState(
  //     {
  //       allQuestions: dummyQuesions,
  //     },
  //     () => {
  //       this.viewQuestionMediaModalRef.show();
  //     }
  //   );
  // };

  // onQuestionTextChange = (e, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["title"] = e.target && e.target.value;
  //   this.setState({
  //     allQuestions: dummyQuesions
  //   })
  // }

  // onQuestionValueChange = (e, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index][e.target.name] = e.target && e.target.value;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onOptionValueChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   dummyQuesions[index]["options"][optIndex][e.target.name] =
  //     e.target && e.target.value;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onOptionIsCorrectChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   dummyQuesions[index]["options"][optIndex][e.target.name] =
  //     !dummyQuesions[index]["options"][optIndex][e.target.name];

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onRadioOptionIsCorrectChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   this.resetRadioButtonValue(dummyQuesions, index);

  //   dummyQuesions[index]["options"][optIndex]["is_correct"] = true;
  //   dummyQuesions[index]["options"]["is_correct_radio"] = e.target.value;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  updateAllQuestionStateAction = (allQuestions) => {
    this.setState({
      allQuestions: allQuestions,
    });
  };

  render() {
    const { primaryColor, course_id } = this.props;
    const {
      allCoursesOptions,
      allSessionsOptions,
      isSample,
      allQuestions,
      selectedParentType,
      selectedCourseType,
      longDescription,
      media,
    } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Course Session Topic"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          className="big-modal"
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                course_id: "",
                media: "",
                // sort_number: "",
                // time: "",
                short_description: "",
              }}
              enableReinitialize
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Name
                        <Input
                          id="name"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>

                      {/* <div className="formik-field-right">
                        Sort Number
                        <Input
                          id="sort_number"
                          placeholder="Sort Number"
                          value={values.sort_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.sort_number && touched.sort_number ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.sort_number}
                          </p>
                        ) : null}
                      </div> */}

                      {/* <div className="formik-field-right">
                        Total Time
                        <Input
                          min={1}
                          id="time"
                          type="number"
                          placeholder="Total Time"
                          value={parseInt(values.time)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.time && touched.time ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.time}
                          </p>
                        ) : null}
                      </div> */}
                    </div>

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Course
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeCourseType(value)}
                          placeholder="Select Course"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {
                            courseListForDropdown.map(course => (
                              <Option value={course._id}>{course.name}</Option>
                            ))
                          }
                        </Select>
                        {this.state.errors.selectedCourseType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCourseType}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Session
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeSessionType(value)}
                          placeholder="Select Session"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {
                            sessionListForDropdown.map(courseSession => (
                              <Option value={courseSession._id}>{courseSession.name}</Option>
                            ))
                          }
                        </Select>
                        {this.state.errors.selectedSessionType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedSessionType}
                          </p>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Parent Type
                        <Select
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeParentType(value)}
                          placeholder="Select Type"
                          showSearch
                          value={selectedParentType}
                          // defaultValue="subAdmin"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allParentTypes}
                        </Select>
                        {this.state.errors.selectedParentType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedParentType}
                          </p>
                        ) : null}
                      </div>

                      {selectedParentType === "course" ? (
                        <div className="formik-field-right">
                          Course
                          {course_id ? (
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select Course"
                              disabled
                              value={selectedCourseType}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {allCoursesOptions}
                            </Select>
                          ) : (
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              onChange={(value) => this.changeCourseType(value)}
                              placeholder="Select Course"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {allCoursesOptions}
                            </Select>
                          )}
                          {this.state.errors.selectedCourseType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedCourseType}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div className="formik-field-right">
                          Session
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeSessionType(value)}
                            placeholder="Select Session"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {allSessionsOptions}
                          </Select>
                          {this.state.errors.selectedSessionType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedSessionType}
                            </p>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Topic Type
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeType(value)}
                          placeholder="Select Type"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allTypes}
                        </Select>
                        {this.state.errors.selectedType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedType}
                          </p>
                        ) : null}
                      </div>

                      {this.state.selectedType === "study" && (
                        <div className="formik-field-right">
                          <Checkbox
                            onChange={(value) => this.onChangeIsFeatured()}
                            checked={isSample}
                          >
                            is Sample?
                          </Checkbox>
                          {this.state.errors.isSample ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.isSample}
                            </p>
                          ) : null}
                        </div>
                      )}

                      {/* <div className="formik-field-right">
                        Total Time
                        <Input
                          id="time"
                          placeholder="Total Time"
                          value={values.time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.time && touched.time ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.time}
                          </p>
                        ) : null}
                      </div> */}
                    </div>

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field">
                        Short Description
                        <TextArea
                          row={4}
                          id="short_description"
                          placeholder="Short Description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.short_description &&
                        touched.short_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.short_description}
                          </p>
                        ) : null}
                      </div>
                    </div> */}

                    {this.state.selectedType === "study" && (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field">
                            Description
                            <EditorField
                              id="about-course-editor"
                              value={longDescription}
                              onChange={this.onDescriptionChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field">
                            Topic Media
                            <Input
                              id="media"
                              placeholder="Topic Media"
                              name="media"
                              value={values.media}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {this.state.errors.media ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.media}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.selectedType === "quiz" && (
                      <>
                        <QuestionComponent
                          allQuestionsData={allQuestions}
                          errors={this.state.errors}
                          updateAllQuestionStateAction={
                            this.updateAllQuestionStateAction
                          }
                        />

                        <Divider></Divider>
                      </>
                    )}

                    {/* Course Topic Media Section Start */}

                    {/* {this.state.selectedType === "study" && (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field">
                            <div className="formik-field-wrap">
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  marginTop: "20px",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ float: "left", width: "10%" }}>
                                  <PictureOutlined
                                    style={{ width: "100%", fontSize: "30px" }}
                                  />
                                </div>
                                <Button
                                  style={{ width: "90%" }}
                                  onClick={this.showMediaModal.bind(this)}
                                >
                                  Choose Media Files
                                </Button>

                                {this.state.errors.media ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                      margin: "0",
                                    }}
                                  >
                                    {this.state.errors.media}
                                  </p>
                                ) : null}

                                {this.state.viewMediaModal ? (
                                  <AddCourseSessionTopicMediaFiles
                                    refx={(e) => (this.viewMediaModalRef = e)}
                                    onUpload={this.uploadMediaFiles}
                                  />
                                ) : null}
                              </div>

                              <div
                                style={{
                                  marginTop: "20px",
                                  width: "100%",
                                  backgroundColor:
                                    this.selectedColorType !== ""
                                      ? this.selectedColorType
                                      : "#ffffff",
                                  borderRadius: "10px",
                                }}
                              >
                                {media && media.length > 0
                                  ? media.map((media, index) => (
                                      <MediaItem
                                        name={media.media_name}
                                        url={`${COURSE_TOPIC_MEDIA_PREVIEW_PATH}${media.media_name}`}
                                        thumbMediaUrl={`${COURSE_TOPIC_MEDIA_PREVIEW_PATH}${media.thumb_media_name}`}
                                      />
                                    ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Divider></Divider>
                      </>
                    )} */}

                    {/* Course Topic Media Section End */}

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

export default connect()(withRouter(AddCourseSessionTopic));
