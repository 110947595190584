import { Button, Divider, notification, Layout, Spin } from "antd";
import Title from "./Title";
import * as React from "react";
import * as moment from "moment";
import styled from "styled-components";
import { editAdmin } from "../../Graphs/Admin/editAdmin";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import EditProfileForm from "./EditProfileForm";
import { Link } from "react-router-dom";

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

const { Content } = Layout;

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      primaryColor: "#4466f2",
      isLoading: true,
    };
  }

  input;

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  editSubmit = async (adminObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        loginUserData,
        updateUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });
      let statusData = await editAdmin(
        authToken,
        adminObject,
        loginUserData._id
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Profile",
          description: "Successfully edited",
        });
        await updateUserData(adminObject);
        history.push(`${process.env.PUBLIC_URL}/edit-profile`);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.log("!!!!!!!!!error in editSubmit function", e);
      notification["error"]({
        message: "Edit User",
        description: e,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor } = this.state;

    const { loginUserData } = this.props;

    return (
      <React.Fragment>
        <Title parent={"Admin Profile"} title={"Admin Profile"} />
        <div>
          {this.state.isLoading ? (
            <Content style={{ background: "#fff", marginLeft: "10px" }}>
              <div style={{ justifyContent: "center", textAlign: "center" }}>
                <Spin />
              </div>
            </Content>
          ) : (
            <div>
              <EditProfileForm
                primaryColor={primaryColor}
                onSubmit={this.editSubmit}
              />
              <div style={{ float: "right" }}>
                <Link to="/edit-profile/mail">
                  <Button
                    // type="primary"
                    style={{
                      marginTop: "19px",
                      marginRight: "15px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                  >
                    Change Email
                  </Button>
                </Link>

                <Link to="/edit-profile/password">
                  <Button
                    // type="primary"
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                  >
                    Change Password
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
    setLoginUserData: (loginUserData) => {
      dispatch({
        type: "SET_USER_DATA",
        loginUserData: loginUserData,
      });
    },
    updateUserData: (adminUserData) => {
      dispatch({
        type: "UPDATE_USER_DATA",
        adminUserData: adminUserData,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditProfile));
