import { combineReducers } from "redux";
import Customizer from "./customizer.reducer";
import auth from "./auth";
import category from "./category";
import user from './user';
import admin from './admin';
import course from './course';
import coursesession from './coursesession';
import coursesessiontopic from './coursesessiontopic';
import language from './language';
import module from './module';
import subcategory from './subcategory';
import faq from './faq';
import testimonial from './testimonial';
import group from './group';
import appcontent from './appcontent';

const reducers = combineReducers({
  Customizer,
  auth,
  category,
  user,
  admin,
  course,
  coursesession,
  coursesessiontopic,
  language,
  module,
  subcategory,
  faq,
  testimonial,
  group,
  appcontent,
});

export default reducers;
