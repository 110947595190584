import { WEBURL } from "../constant/comman";
import { encryptBody } from "../service/encryption";

export async function callApiToServer(body, header, method, endPoint) {
  // console.log(
  //   "encryptedString in about to call adminLogin",
  //   body,
  //   header,
  //   method,
  //   endPoint
  // );
  console.log('!!!!!!body in comman api function', body);

  // let encryptedString = await encryptBody(body);
  // console.log('encrypted string',encryptedString);

  // let sendBody = JSON.stringify({
  //   data: encryptedString,
  // });

  return new Promise((resolve, reject) => {
    fetch(WEBURL + endPoint, {
      method: method,
      headers: header,
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("error printed here in callApi", err);
        reject(err);
      });
  });
}

export function callGetApiToServer(header, method, endPoint) {
  console.log("in about to call adminLogin", header, method, endPoint);

  return new Promise((resolve, reject) => {
    fetch(WEBURL + endPoint, {
      method: method,
      headers: header,
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("error printed here in callApi", err);
        reject(err);
      });
  });
}


export async function callApiToServerForFile(body, header, method, endPoint) {
  // console.log(
  //   "encryptedString in about to call adminLogin",
  //   body,
  //   header,
  //   method,
  //   endPoint
  // );
  console.log('!!!!!!body in comman api function', body);

  // let encryptedString = await encryptBody(body);
  // console.log('encrypted string',encryptedString);

  // let sendBody = JSON.stringify({
  //   data: encryptedString,
  // });

  return new Promise((resolve, reject) => {
    fetch(WEBURL + endPoint, {
      method: method,
      headers: header,
      data: body,
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("error printed here in callApi for files", err);
        reject(err);
      });
  });
}