import { callApiToServer } from "../callApi";

export async function deleteCourse(authtoken, courseId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      "course_id":courseId
    })
    let endUrl = `v1/auth/admin/delete-course`;
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body,header, "DELETE", endUrl);
    console.log(responseData);
    return responseData;
  } catch (err) {
    throw err;
  }
}
