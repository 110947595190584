import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import Title from "./Title";
import { AddCourseSession } from "./AddCourseSession";
import { addCourseSession } from "../../Graphs/CourseSession/addCourseSession";
import CourseSessionRecordsTable from "./CourseSessionRecordsTable";
import { getAllCourseSession } from "../../Graphs/CourseSession/listCourseSession";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class CourseSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseSessionList: [],
      courseSessionListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(this.props.loginUserData.permissions, "course", "view")
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText, course_id } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      let routeName = "COURSESESSION";
      if (course_id) {
        routeName = "COURSE_COURSESESSION";
      }

      await setRouteName(routeName);
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCourseSessionData();
    } catch (e) {
      notification["success"]({
        message: "Topic",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCourseSessionData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCourseSessionData,
      setListCourseSessionDataTotal,
      course_id,
    } = this.props;

    try {
      let courseSessionData = await getAllCourseSession(
        authToken,
        0,
        10,
        course_id
      );

      if (courseSessionData.status === 200) {
        //set user data
        await setListCourseSessionData(courseSessionData.data);
        // await setListCourseSessionData(courseSessionData.data.docs);
        await setListCourseSessionDataTotal(courseSessionData.total);

        this.setState({
          courseSessionList: courseSessionData.data,
          // courseSessionList: courseSessionData.data.docs,
          courseSessionListTotal: courseSessionData.total,
        });
      } else if (courseSessionData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    const { authToken, history, setLoginFlag, setUserData, setUserToken } =
      this.props;

    try {
      this.setState({
        isLoading: true,
      });
      console.log(values);
      let addCourseSessionData = await addCourseSession(authToken, values);

      if (addCourseSessionData.status === 200) {
        await this.listCourseSessionData();
        notification["success"]({
          message: "Add Session Status",
          description: "Session added successfully",
        });
      } else if (addCourseSessionData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Add Session Status",
        description: "There was a problem in Adding Session",
      });
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const {
      listCourseSessionData,
      listCourseSessionDataTotal,
      loginUserData,
      authToken,
      course_id,
    } = this.props;

    return (
      <Fragment>
        {!course_id ? (
          <Title parent="Course Session" title="Course Session" />
        ) : null}

        {checkPermission(loginUserData.permissions, "course", "create") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddCourseSession
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                authToken={authToken}
                course_id={course_id}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CourseSessionRecordsTable
                listCourseSessionData={listCourseSessionData}
                listCourseSessionDataTotal={listCourseSessionDataTotal}
                primaryColor={primaryColor}
                course_id={course_id}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listCourseSessionData: state.coursesession.listCourseSessionData,
  listCourseSessionDataTotal: state.coursesession.listCourseSessionDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCourseSessionData: (listCourseSessionData) => {
      dispatch({
        type: "SET_LIST_COURSE_SESSION_DATA",
        listCourseSessionData: listCourseSessionData,
      });
    },
    setListCourseSessionDataTotal: (listCourseSessionDataTotal) => {
      dispatch({
        type: "SET_LIST_COURSE_SESSION_DATA_TOTAL",
        listCourseSessionDataTotal: listCourseSessionDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseSession));
