import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, Row, Checkbox } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { MODULES, ROLES } from "../../constant/comman";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedType: "",
      errors: {},
    };
  }

  input;
  permissionsData = {};

  allTypes = [
    <Option value={"subAdmin"} key="subAdmin">
      Sub Admin
    </Option>,
    <Option value={"superAdmin"} key="superAdmin">
      Super Admin
    </Option>,
  ];

  /**
   * set initial permission false for the sub admin user in below format for the defined modules
   * with defined actions in constant
   *
   * FORMAT: dashboard(module_name): { view(role_name): false, create: false, edit: false, delete: false },
   */
  setInitialPermission = (adminType = "subAdmin") => {
    MODULES.map((moduleName) =>
      ROLES[moduleName.value].map(
        (roleName) =>
          (this.permissionsData[moduleName.value] = {
            ...this.permissionsData[moduleName.value],
            [roleName.toLowerCase()]: adminType === "superAdmin" ? true : false,
          })
      )
    );
  };

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    // if (!values.username) {
    //   errors.username = "username is required";
    // }

    if (!values.email) {
      errors.email = "email is required";
    }

    if (!values.password) {
      errors.password = "password is required";
    }

    if (values.password.length < 8) {
      errors.password = "password length should be greater than 8";
    }

    if (!values.first_name) {
      errors.first_name = "first name is required";
    }

    if (!values.last_name) {
      errors.last_name = "last name is required";
    }

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    console.log(this.props._id);
    this.props.onSubmit(values, this.props.data._id);
    this.setState({
      visible: false,
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
    this.setInitialPermission(value);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  render() {
    const { primaryColor, data } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            // username: data.username,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            // password: data.password,
            permissions: data.permissions,
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  {/* <div className="formik-field-left">
                    Username
                        <Input
                      id="username"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.username && touched.username ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.username}
                      </p>
                    ) : null}
                  </div> */}

                  <div className="formik-field-left">
                    First Name
                    <Input
                      id="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.first_name && touched.first_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.first_name}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Last Name
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.last_name && touched.last_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.last_name}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Email
                    <Input
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.email}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Phone
                    <Input
                      id="phone"
                      placeholder="Phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phone && touched.phone ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.phone}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Admin Type
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) => this.changeAdminType(value)}
                      placeholder="Select Admin Type"
                      showSearch
                      defaultValue={data.role}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.allTypes}
                    </Select>
                    {this.state.errors.selectedAdminType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {this.state.errors.selectedAdminType}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* {data.role === "subAdmin" &&
                  MODULES.map((moduleName, moduleIndex) => (
                    <div key={moduleIndex}>
                      <div className="col-md-12">
                        <div className="row">
                          <label>
                            <strong>{moduleName.label}</strong>
                          </label>
                        </div>
                        <div className="row">
                          <div className="formik-field">
                            {ROLES[moduleName.value].map(
                              (roleName, roleIndex) => (
                                <span key={roleIndex}>
                                  <Checkbox
                                    onChange={handleChange}
                                    checked={
                                      values.permissions[moduleName.value][
                                        roleName.toLowerCase()
                                      ]
                                    }
                                    name={`permissions.${
                                      moduleName.value
                                    }.${roleName.toLowerCase()}`}
                                  >
                                    {roleName}
                                  </Checkbox>
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <Divider></Divider>
                    </div>
                  ))} */}

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(EditForm));
