import { Modal, Button, Divider, notification } from "antd";
import Title from "./Title";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as la from "lodash";
import { Link } from "react-router-dom";
import { editAdmin } from "../../Graphs/Admin/editAdmin";

const { Content } = Layout;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class ChangeAdminEmail extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {},
    };
  }

  input;

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm(values) {
    const errors: any = {};
    console.log("Validating form -- ", values);

    if (!values.current_email) {
      errors.current_email = "Current Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.current_email).toLowerCase()
      )
    ) {
      errors.current_email = "Please enter a valid Current Email";
    } else if (values.existing_email != values.current_email) {
      errors.current_email = "Current Email is incorrect";
    }

    if (!values.new_email) {
      errors.new_email = "New Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.new_email).toLowerCase()
      )
    ) {
      errors.new_email = "Please enter a valid New Email";
    } else if (values.new_email == values.current_email) {
      errors.new_email = "New Email and Current Email could not be same";
    } else if (values.existing_email == values.new_email) {
      errors.new_email = "Please choose different Email";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {
    console.log(values);
    console.log("Basic Value - ", values);

    this.changeAdminEmail(values);

    this.setState({
      visible: false,
    });
  }

  async changeAdminEmail(adminObject) {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;

      this.setState({
        isLoading: true,
      });
      console.log("edit user id ================================");
      console.log(adminObject);
      let statusData = await editAdmin(authToken, adminObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Admin Profile",
          description: "Email changed successfully, Please login again",
        });
        // history.push(`${process.env.PUBLIC_URL}/edit-profile`);
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!!error in editSubmit function", e);
      notification["error"]({
        message: "Edit Admin Profile",
        description: e,
      });
    }
  }

  render() {
    const { adminUserEmail } = this.props;

    return (
      <div>
        <div>
          <Title parent="Edit Email" title="Admin Profile" />
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                current_email: "",
                new_email: "",
                existing_email: adminUserEmail,
              }}
              enableReinitialize
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div className="container-fluid p-20">
                    <div className="form-group">
                      <div className="formik-field">
                        Current Email
                        <Input
                          id="current_email"
                          placeholder="Current Email"
                          value={values.current_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.current_email && touched.current_email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.current_email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="formik-field">
                        New Email
                        <Input
                          id="new_email"
                          placeholder="New Mail"
                          value={values.new_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_email && touched.new_email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.new_email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update Email
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>

          {/* <div style={{ float: "right" }}>
                        <Link to="/edit-profile/password">
                            <Button
                                // type="primary"
                                style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }}
                            >
                                Change Password
                            </Button>
                        </Link>
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchText: state.auth.searchText,
  adminUserEmail: state.auth.loginUserData.email,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangeAdminEmail));
