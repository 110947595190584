import { Divider, Row, Col } from "antd";
import * as React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { WEBURL } from "../../constant/comman";
import {
  COURSE_BANNER_PREVIEW_PATH,
  COURSE_BENEFIT_PREVIEW_PATH,
  COURSE_PRIMARY_PREVIEW_PATH,
  COURSE_INTRO_PREVIEW_PATH,
} from "../../constant/comman";
import MediaItem from "../Media/MediaItem";

const { Content } = Layout;

export class CoursePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: {},
    };
  }

  input;

  render() {
    const { data } = this.props;

    return (
      <div>
        <div>
          <Content>
            <div>
              <div>
                <div>
                  <div
                    style={{ float: "left", width: "80%", paddingLeft: "30px" }}
                  >
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Name: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.name}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Author: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.author}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Short Description: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.short_description}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Long Description: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.long_description}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Summery: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.summary_description}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Category: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.name}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Sub Category: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.subCategoryData
                            ? data.subCategoryData
                                .map((item) => item.name)
                                .join(", ")
                            : ""}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Languages: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.languageData
                            ? data.languageData
                                .map((item) => item.name)
                                .join(", ")
                            : ""}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Skills: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.skillData
                            ? data.skillData.map((item) => item.name).join(", ")
                            : ""}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Total Time: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.total_time}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Validity: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.name}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Difficulty Level: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.difficulty_level}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Price: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.is_paid ? data.price : "Free"}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Restricted Age: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.is_age_restricted
                            ? data.age_restriction_count
                            : "Not Restricted"}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Is Certified: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.is_certified ? "True" : "False"}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Is Featured: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>{data.is_featured ? "True" : "False"}</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Primary: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.courseMediaData.map((item, index) => (
                            <>
                              <MediaItem
                                name={item.media_name}
                                url={`${COURSE_PRIMARY_PREVIEW_PATH}${item.media_name}`}
                                width={100}
                                key={index}
                              />
                            </>
                          ))}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Banner: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.courseBannerMedia.map((item, index) => (
                            <MediaItem
                              name={item.media_name}
                              url={`${COURSE_BANNER_PREVIEW_PATH}${item.media_name}`}
                              width={100}
                              key={index}
                            />
                          ))}
                        </span>
                      </Col>
                    </Row>
                    {/* <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Intro: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.courseIntroMedia.map((item, index) => (
                            <>
                              <MediaItem
                                name={item.media_name}
                                url={`${COURSE_INTRO_PREVIEW_PATH}${item.media_name}`}
                                thumbMediaUrl={`${COURSE_INTRO_PREVIEW_PATH}${item.thumb_media_name}`}
                                width={100}
                                key={index}
                              />
                            </>
                          ))}
                        </span>
                      </Col>
                    </Row> */}
                    <Row
                      gutter={[16, 16]}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Col span={4}>
                        <span>
                          <strong>Benefits: </strong>
                        </span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {data.benefitData.map((item, index) => (
                            <div
                              style={{
                                marginBottom: "15px",
                                display: "inline-block",
                                width: "100%",
                                paddingBottom: "15px",
                                borderBottom: "2px dashed gray",
                              }}
                              key={index}
                            >
                              <div
                                style={{
                                  marginTop: "5px",
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <span
                                  style={{ fontWeight: "bold", float: "left" }}
                                >
                                  {`Benefit ${index + 1}`}
                                </span>
                              </div>

                              <div className="formik-field_wrap custom30Width">
                                <div className="formik-field_wrap">
                                  <div className="formik-field">
                                    <span style={{ fontWeight: "bold" }}>
                                      {`Name: `}
                                    </span>
                                    <span>{item.name}</span>
                                  </div>
                                </div>

                                <div className="formik-field_wrap">
                                  <div className="formik-field">
                                    <span style={{ fontWeight: "bold" }}>
                                      {`Short Description: `}
                                    </span>
                                    <span>{item.short_description}</span>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="formik-field_wrap"
                                style={{ marginTop: "5px" }}
                              >
                                <div className="formik-field">
                                  {item.icon_name ? (
                                    <MediaItem
                                      name={item.icon_name}
                                      url={`${COURSE_BENEFIT_PREVIEW_PATH}${item.icon_name}`}
                                      width={100}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
      </div>
    );
  }
}

export default withRouter(CoursePreview);
