import { callApiToServer } from "../callApi";

export async function addCourseSession(authtoken, courseObject) {
  console.log('calling');
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    console.log('!!!!!!!courseObject', courseObject);
    let body = JSON.stringify(courseObject);

    let endUrl = "v1/auth/admin/add-course-session";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
