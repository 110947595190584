import React, { Component } from "react";
import { Select, Layout, Button, notification, Spin } from "antd";
import { listCourseSortData } from "../../Graphs/Course/listCourseSortData";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { saveCourseSortData } from "../../Graphs/Course/saveCourseSortData";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const { Content } = Layout;
const Option = Select.Option;

class CourseDataSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      courseData: [],
      sessionList: [],
    };
  }

  componentDidMount() {
    this.getCourseSessionTopicData();
  }

  getCourseSessionTopicData = async () => {

    const { history, setLoginFlag, setUserData, setUserToken } = this.props;

    try {
      const { authToken, course_id } = this.props;

      this.setState({
        isLoading: true
      });

      const courseSortData = await listCourseSortData(authToken, course_id);

      if (courseSortData.status === 200) {
        this.setState({ courseData: courseSortData.data })
      } else if (courseSortData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addSessionTopic = (data) => {
    const { courseData, sessionList } = this.state;
    let dummyCourseData = courseData;
    let dummySesionList = sessionList;

    let filterData = courseData.filter((subData, subIndex) => {
      if (data.type === subData.type && data.name === subData.name) {
        return true;
      }
    });

    if (filterData && filterData.length > 0) {
      return;
    }

    if (data.sessionId) {
      let filterSessionData = courseData.filter((subData, subIndex) => {
        if (data.sessionId === subData.name) {
          return true;
        }
      });

      if (filterSessionData && filterSessionData.length > 0) {
        let dummyFiltersessionData = filterSessionData[0];
        let dummyTopics = dummyFiltersessionData.topics;

        dummyTopics.push({
          type: data.type,
          isModule: data.isModule,
          isTopic: data.isTopic,
          name: data.name,
          sessionId: data.sessionId,
        });

        let dummyCourseData = [];
        courseData.map((subData, subIndex) => {
          if (data.sessionId === subData.name) {
            dummyCourseData.push({ ...subData, topics: dummyTopics });
          } else {
            dummyCourseData.push(subData);
          }
        });

        this.setState({
          courseData: dummyCourseData,
        });
      }

      return;
    }

    dummyCourseData.push({
      type: data.type,
      isModule: data.isModule,
      isTopic: data.isTopic,
      name: data.name,
      sessionId: data.sessionId,
      topics: [],
    });

    if (!data.isTopic) {
      dummySesionList.push(
        <Option key={data.name} value={data.name}>
          {data.name}
        </Option>
      );
    }

    this.setState({
      sessionList: dummySesionList,
      courseData: dummyCourseData,
    });
  };

  mainUp = (data, index) => {
    const { courseData } = this.state;
    let dummyCourseData = courseData;

    if (index === 0) {
      return;
    }

    var element = dummyCourseData[index];
    dummyCourseData.splice(index, 1);
    dummyCourseData.splice(index - 1, 0, element);

    this.setState({
      courseData: dummyCourseData,
    });
  };

  mainDown = (data, index) => {
    const { courseData } = this.state;
    let dummyCourseData = courseData;

    if (index === courseData.length - 1) {
      return;
    }

    var element = dummyCourseData[index];
    dummyCourseData.splice(index, 1);
    dummyCourseData.splice(index + 1, 0, element);

    this.setState({
      courseData: dummyCourseData,
    });
  };

  subUp = (data, index, sessionData) => {
    const { courseData } = this.state;

    let filterData = courseData.filter((subData, subIndex) => {
      if (sessionData._id === subData._id) {
        return true;
      }
    });

    if (!(filterData && filterData.length > 0)) {
      return;
    }

    if (index === 0) {
      return;
    }

    let dummyTopicsArray = filterData[0]["sessionTopicData"];

    var element = dummyTopicsArray[index];
    dummyTopicsArray.splice(index, 1);
    dummyTopicsArray.splice(index - 1, 0, element);

    let dummyCourseData = [];
    courseData.map((subData, subIndex) => {
      if (sessionData._id === subData._id) {
        dummyCourseData.push({ ...subData, topics: dummyTopicsArray });
      } else {
        dummyCourseData.push(subData);
      }
    });

    this.setState({
      courseData: dummyCourseData,
    });
  };

  subDown = (data, index, sessionData) => {
    const { courseData } = this.state;

    let filterData = courseData.filter((subData, subIndex) => {
      if (sessionData._id === subData._id) {
        return true;
      }
    });

    if (!(filterData && filterData.length > 0)) {
      return;
    }

    let dummyTopicsArray = filterData[0]["sessionTopicData"];
    if (index === dummyTopicsArray.length - 1) {
      return;
    }

    var element = dummyTopicsArray[index];
    dummyTopicsArray.splice(index, 1);
    dummyTopicsArray.splice(index + 1, 0, element);

    let dummyCourseData = [];
    courseData.map((subData, subIndex) => {
      if (sessionData._id === subData._id) {
        dummyCourseData.push({ ...subData, topics: dummyTopicsArray });
      } else {
        dummyCourseData.push(subData);
      }
    });

    this.setState({
      courseData: dummyCourseData
    });
  };

  handleSave = () => {
    const { courseData } = this.state;
    const { course_id } = this.props;

    let saveData = {
      course_id: course_id,
      dataArray: courseData
    };

    console.log("!!!!!!courseData", saveData);

    this.saveSubmit(saveData);
  };

  saveSubmit = async (data) => {

    const { history, setLoginFlag, setUserData, setUserToken } = this.props;

    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let sortData = await saveCourseSortData(authToken, data);

      if (sortData.status === 200) {
        notification["success"]({
          message: "Session Topic Added Successfully"
        });
        await this.getCourseSessionTopicData();
      }
      else if (sortData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "There was a problem in save data"
      });
      console.log("!!!!!!!!e", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { courseData, sessionList, isLoading } = this.state;

    return (
      <div>
        <Content>
          <div className="container-fluid">
            {isLoading ? (
              <div style={{ marginLeft: "20px" }}>
                <Spin
                  size="large"
                  style={{ marginLeft: "480px", marginTop: "130px" }}
                />
              </div>
            ) : (
              <div className="formik-field_wrap">
                <div style={{ float: "left", width: "80%", paddingLeft: "30px" }}>
                  <div style={{ marginBottom: "10px", marginTop: "10px", fontWeight: "bold" }} >
                    Manage Course List
                  </div>
                  {courseData &&
                    courseData.map((data, index) => {
                      if (data.type === "session") {
                        return (
                          <>
                            <div className="col-12 row align-items-center border ml-0 mb-2" key={index}>
                              <div className="mr-5">
                                <div style={{ marginBottom: "10px", cursor: "pointer" }} onClick={() => this.mainUp(data, index)}>
                                  <UpOutlined />
                                </div>
                                <div style={{ float: "left", width: "100%", cursor: "pointer" }} onClick={() => this.mainDown(data, index)}>
                                  <DownOutlined />
                                </div>
                              </div>
                              <div>
                                <strong>{data.name} <span>{"(Session)"}</span></strong>
                              </div>
                            </div>

                            <div style={{ marginLeft: "50px" }}>
                              {data.sessionTopicData &&
                                data.sessionTopicData.map((subData, subIndex) => {
                                  return (
                                    <div className="col-12 row align-items-center border ml-0 mb-2" key={subIndex}>
                                      <div className="mr-5">
                                        <div style={{ marginBottom: "10px", cursor: "pointer" }} onClick={() => this.subUp(subData, subIndex, data)}>
                                          <UpOutlined />
                                        </div>
                                        <div style={{ float: "left", width: "100%", cursor: "pointer" }} onClick={() => this.subDown(subData, subIndex, data)}>
                                          <DownOutlined />
                                        </div>
                                      </div>
                                      <div>
                                        <strong>{subData.name} <span>{"(Topic)"}</span></strong>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        );
                      }

                      return (
                        <div className="col-12 row align-items-center border ml-0 mb-2" key={index}>
                          <div className="mr-5">
                            <div style={{ marginBottom: "10px", cursor: "pointer" }} onClick={() => this.mainUp(data, index)}>
                              <UpOutlined />
                            </div>
                            <div style={{ float: "left", width: "100%", cursor: "pointer" }} onClick={() => this.mainDown(data, index)}>
                              <DownOutlined />
                            </div>
                          </div>
                          <div >
                            <strong>{data.name} <span>{data.type === "topic" ? "(Topic)" : data.type === "session" ? "(Session)" : "(Module)"}</span></strong>
                          </div>
                        </div>
                      );
                    })
                  }

                  <div style={{ marginTop: "10px" }} >
                    <Button style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }} onClick={() => this.handleSave()} >
                      Save
                    </Button>
                  </div>
                </div >
              </div>
            )}
          </div>
        </Content>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDataSorting));
