import { callApiToServer } from '../callApi';

export async function changeStatus(authtoken, languageId, status) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    let body = JSON.stringify({
      Language_id: languageId,
      is_active: status,
    });

    let endUrl = "v1/auth/admin/language/active-status-language";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
